/* =========================================================================================
    File Name: featherIcon.scss
    Description: Feather icon component's scss. Imported in FeatherIcon.vue file
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

.feather-icon {
  display: inline-flex;
  align-items: center;
}
