










































































































































.v-step,
.v-step__header {
  background: #ea5455 !important;
}
.v-step__arrow {
  border-color: #ea5455 !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
