/* =========================================================================================
    File Name: _datePicker.scss
    Description: Styles for datepicker component.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

@import '../../variables/variables.scss';

.vdp-datepicker {
  input {
    width: 100%;
    padding: 0.7rem;
    color: $content-color;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  input[disabled] {
    opacity: 0.5;
  }

  .vdp-datepicker__calendar {
    z-index: 401;
    border-radius: 0.5rem;

    header {
      .pre {
        border-top-left-radius: 0.5rem;
      }
      .next {
        border-top-right-radius: 0.5rem;
      }
    }
  }

  .day.blank {
    background: transparent;
  }

  .cell {
    &.day {
      width: 12.285714286%;
      height: 37px;
      margin-right: 1%;
      margin-left: 1%;
      line-height: 37px;
      border-radius: 50%;
    }

    &.month,
    &.year {
      border-radius: 0.5rem;
    }

    &:not(.blank):hover {
      background-color: #eee;
      // border-color: rgba(var(--vs-primary),1) !important;
      border-color: rgba(0, 0, 0, 0) !important;
    }

    &.day.highlighted {
      color: $white;
      background: rgba(var(--vs-primary), 0.7);

      &:hover {
        background: rgba(var(--vs-primary), 1);
      }
    }

    &.selected {
      color: $white;
      background: rgba(var(--vs-primary), 1) !important;

      &:hover {
        background: rgba(var(--vs-primary), 1);
      }
    }
  }
}
