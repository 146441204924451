@import '../../variables/variables.scss';

.vx-input-group {
  .vs-con-textarea {
    margin-bottom: 0;
  }

  // prepend style
  .vx-input-group-prepend {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    .prepend-text {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.18);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      // border radius end

      &.btn-addon {
        .button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &:not(:first-child) {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  // append style
  .vx-input-group-append {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    .append-text {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.18);
      }

      // border radius
      // &:not(:first-child) {
      //   border-top-right-radius: 0;
      //   border-bottom-right-radius: 0;
      // }

      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      // border radius end

      &.btn-addon {
        .button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          &:not(:last-child) {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  .prepend-text,
  .append-text {
    color: $white;
    &:not(.btn-addon) {
      padding-right: 1rem;
      padding-left: 1rem;
      border-radius: 6px;
    }

    &.btn-addon {
      .button {
        height: 100%;
        padding: 0 1.5rem !important;

        // &:first-child {
        //   border-top-right-radius: 0;
        //   border-bottom-right-radius: 0;
        //   padding: 100px;
        // }
      }
    }
  }

  // default slot style
  .vx-input-group-default {
    // remove left radius if not first child
    &:not(:first-child) {
      input,
      .vs-con-textarea {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .vs-input {
      width: 100%;
    }

    // remove right radius if not last child
    &:not(:last-child) {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
