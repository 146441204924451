.form-wrap {
  display: flex;
  flex-wrap: wrap;
  .formulate-input {
    flex: 1 0 33%;
    margin: 5px;
  }
}
#page-user-edit {
  height: 100%;
}
.card-border-pri {
  border: #ea5455;
  border-style: solid;
  border-width: 0 0 0 10px;
}
.card-border-sec {
  border: #66999b;
  border-style: solid;
  border-width: 0 0 0 10px;
}
.card-border-warn {
  border: #ff9f43;
  border-style: solid;
  border-width: 0 0 0 10px;
}

.tabs-container {
  .schema {
    padding: 1.5em 1em 3em 1em;
  }
}

.Panel-StyledPanel {
  // margin-bottom: 20px;
  position: relative;
  // margin: 10px;
  //   grid-template-rows: repeat(2, auto);
  gap: 30px;
  padding: 30px 20px 20px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(198, 190, 207);
  border-radius: 4px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.SidePanel {
  height: max-content;
}

.OverviewHeading {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 12px;
  font-size: 18px;
  -webkit-box-align: center;
  place-items: center;
  //   text-align: center;
}
.StyledSettingsWrapper {
  display: flex;
  flex: 1 1 0%;
  margin-bottom: -20px;
  color: rgb(48, 40, 57);
  font-size: 16px;
  line-height: 1;
}
.FlexBox-DetailItems {
  display: block;
  box-sizing: border-box;
  width: 257.625px;
  min-width: 0;
  margin: 0;
  color: rgb(100, 85, 116);
  font-size: 16px;
}
.DetailTitle {
  margin-bottom: 8px;
  color: rgb(100, 85, 116);
  font-size: 12px;
  text-transform: uppercase;
}
.DetailPrice {
  justify-self: end;
  color: rgb(100, 85, 116);
}
.TotalPrice {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  font-size: 56px;
}
.Toggle {
  position: absolute;
  top: 4px;
  display: block;
  width: 14px;
  height: 14px;
  background: #66999b;
  border-radius: 50%;
  transform: translateX(28px);
  transition: all 0.25s ease 0s;
}
.IntervalSwitch span {
  background: #66999b;
}
.SwitchButton {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  padding: 0;
  background: none;
  border: 1px solid rgb(198, 190, 207);
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, 0.04) 0 1px 1px inset;
  cursor: pointer;
  transition: border 0.15s ease 0s;
}

.CheckoutContainer {
  //   display: flex;
  gap: 20px;
  //   grid-template-columns: 58% auto;
}
.IntervalSwitch {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  align-items: center;
  margin: 0;
  color: rgb(100, 85, 116);
  font-weight: normal;
  font-size: 14px;
  -webkit-box-align: center;
}

.DetailItem {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid rgb(231, 225, 236);
  -webkit-box-pack: justify;
  -webkit-box-align: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
}

.vue-form-wizard {
  background: #fff;

  .wizard-title {
    color: white;
    font-weight: 700;
  }
}
