/* =========================================================================================
  File Name: _layoutVertical.scss
  Description: Vertical Layout Styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

@import '../../variables/variables';

.main-vertical {
  &.navbar-sticky {
    .router-content {
      margin-top: 4.5rem;
    }
  }

  &.navbar-static {
    .vx-navbar-wrapper {
      .vx-navbar {
        background: transparent !important;
        box-shadow: none;
      }
    }

    .router-content {
      margin-top: 0.2rem;
    }
  }
}

// For application pages
.no-scroll {
  .router-view {
    padding-bottom: 0 !important;
  }

  &.navbar-floating {
    .no-scroll-content {
      height: calc(var(--vh, 1vh) * 100 - 11.5rem);
    }

    &.footer-hidden {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 9.3rem);
      }
    }

    &.footer-sticky {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 13.1rem);
      }
    }
  }

  &.navbar-sticky {
    .no-scroll-content {
      height: calc(var(--vh, 1vh) * 100 - 10.5rem);
    }

    &.footer-hidden {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 8.3rem);
      }
    }

    &.footer-sticky {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 12.1rem);
      }
    }
  }

  &.navbar-static {
    &.footer-static {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 9.25rem);
      }
    }

    &.footer-sticky {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 10.9rem);
      }
    }

    &.footer-hidden {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 7rem);
      }
    }
  }

  &.navbar-hidden {
    .no-scroll-content {
      height: calc(var(--vh, 1vh) * 100 - 6rem);
    }

    &.footer-hidden {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 3.8rem);
      }
    }

    &.footer-sticky {
      .no-scroll-content {
        height: calc(var(--vh, 1vh) * 100 - 7.6rem);
      }
    }
  }
}
