/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* purgecss end ignore */

/* BUTTON CLASSES */

.cib-button {
  @apply inline-flex justify-center px-4 py-2 border border-red-600 bg-red-600 hover:bg-red-500 text-sm font-medium rounded text-white focus:border-red-300 transition duration-150 ease-in-out;
}

.red-button-sm {
  @apply inline-flex justify-center px-4 py-2 border border-red-600 bg-red-600 hover:bg-red-500 text-sm font-medium rounded text-white focus:border-red-300 transition duration-150 ease-in-out;
}

.red-button-lg {
  @apply inline-flex justify-center px-5 py-3 border border-red-600 bg-red-600 hover:bg-red-500 font-medium rounded text-lg text-white focus:border-red-300 transition duration-150 ease-in-out;
}

.white-button-outline-red {
  @apply inline-flex justify-center px-4 py-2 border border-red-600 bg-white hover:border-red-400 text-red-600 hover:text-red-400 text-sm font-medium rounded transition duration-150 ease-in-out;
}

.white-button-outline-red-lg {
  @apply inline-flex justify-center px-5 py-3 border border-red-600 bg-white hover:border-red-400 text-lg text-red-600 hover:text-red-400 font-medium rounded transition duration-150 ease-in-out;
}

.white-button-outline-gray {
  @apply inline-flex justify-center px-4 py-2 border border-gray-300 bg-white hover:bg-gray-100 text-sm font-medium rounded text-gray-700 transition duration-150 ease-in-out;
}

/*
.white-button-lg {
  @apply inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out
}

.teal-button-sm {
  @apply inline-flex items-center px-4 py-2 border border-teal-600 bg-teal-600 hover:bg-teal-500 text-sm font-medium rounded text-white focus:border-teal-300 transition duration-150 ease-in-out;
}

.teal-button-lg {
  @apply inline-flex items-center px-4 py-2 border border-teal-600 bg-teal-600 hover:bg-teal-500 text-sm font-medium rounded text-white focus:border-teal-300 transition duration-150 ease-in-out;
} */

/* BOOKING TABLE CLASSES */
.tb-column-head {
  @apply px-4 py-4 whitespace-nowrap text-left text-xs font-medium uppercase;
}

.tbl-head {
  @apply px-4 py-4 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase dark:text-white;
}

.tbl-data {
  @apply px-4 py-4 text-left text-xs font-medium text-gray-600 dark:text-white dark:bg-gray-700 uppercase;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(107, 114, 128) inset !important;
}
input:-internal-autofill-selected {
  @apply dark:bg-gray-500 dark:text-white;
}
.formulate-input[data-classification='text'] input {
  @apply dark:bg-gray-500 dark:text-white;
}
.formulate-input[data-classification='text'] label {
  @apply dark:text-white;
}
.formulate-input[data-classification='textarea'] label {
  @apply dark:text-white;
}
.formulate-input[data-classification='select'] label {
  @apply dark:text-white;
}
.formulate-input[data-classification='select'] input {
  @apply dark:text-white dark:bg-gray-500;
}
.formulate-input[data-classification='file'] label {
  @apply dark:text-white;
}
.formulate-input[data-classification='image'] label {
  @apply dark:text-white;
}
.formulate-input[data-classification='textarea'] textarea {
  @apply dark:bg-red-500 dark:text-white;
}

.formulate-input[data-type='email'] input:focus {
  @apply ring-teal-500;
}

.apexcharts-text {
  @apply dark:bg-gray-500 dark:text-white;
}

.dark-bg-mode {
  @apply dark:bg-gray-800 dark:text-white;
}

.underline {
  text-decoration: underline rgba(240, 82, 82, var(--tw-bg-opacity));
}

input[type='text']:focus,
[type='time']:focus,
[type='email']:focus,
[type='tel']:focus,
[type='number']:focus {
  @apply ring-red-500 ring-offset-red-300 border-red-500;
}

.guest-booking-page {
  .wizard-header {
    @apply bg-gray-500 text-white;
  }
}

:root {
  --vs-primary: 234, 84, 85;
  --vs-danger: 255, 71, 87;
  --vs-success: 0, 132, 137;
  --vs-dark: 30, 30, 30;
  --vs-warning: 255, 159, 67;
  --vs-light: 245, 245, 245;
}

/* PEACH PAYMENTS FORM */

.wpwl-button.wpwl-button-pay {
  @apply inline-flex justify-center px-4 py-2 border border-red-600 bg-red-600 hover:bg-red-500 text-sm font-medium rounded text-white focus:border-red-300 transition duration-150 ease-in-out;
}
.wpwl-control {
  @apply block w-full border-gray-300 rounded-sm  focus:ring-red-500 focus:border-red-500 sm:text-sm h-8;
}

.wpwl-wrapper-cvv {
  @apply focus:ring-red-500 focus:ring-offset-red-300 focus:border-red-500;
}
.wpwl-wrapper-cardNumber {
  @apply focus:ring-red-500 focus:ring-offset-red-300 focus:border-red-500;
}

.wpwl-group {
  @apply col-span-full;
}

.wpwl-label {
  @apply block text-sm font-medium text-gray-700 float-none;
}

.wpwl-form {
  @apply grid grid-cols-2 gap-y-3 gap-x-4 max-w-5xl m-auto;
}

.wpwl-button {
  @apply hidden !important;
}

/* .cnpBillingCheckoutWrapper {
  position: relative;
}
.cnpBillingCheckoutHeader {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #c0c0c0;
}
.cnpBillingCheckoutLeft {
  display: inline-block;
  width: 240px;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 10px;
  vertical-align: top;
  border: 1px solid #c0c0c0;
}
.cnpBillingCheckoutRight {
  display: inline-block;
  width: 50%;
  margin-left: 5px;
  padding: 10px;
  vertical-align: top;
  border: 1px solid #c0c0c0;
}
.cnpBillingCheckoutOrange {
  color: rgb(255, 60, 22);
  font-weight: bold;
  font-size: 110%;
}
div.wpwl-wrapper,
div.wpwl-label,
div.wpwl-sup-wrapper {
  width: 100%;
}
div.wpwl-group-expiry,
div.wpwl-group-brand {
  float: left;
  width: 30%;
}
div.wpwl-group-cvv {
  float: left;
  width: 68%;
  margin-left: 2%;
}
div.wpwl-group-cardHolder,
div.wpwl-sup-wrapper-street1,
div.wpwl-group-expiry {
  clear: both;
}
div.wpwl-sup-wrapper-street1 {
  padding-top: 1px;
}
div.wpwl-wrapper-brand {
  width: auto;
}
div.wpwl-sup-wrapper-state,
div.wpwl-sup-wrapper-city {
  float: left;
  width: 32%;
  margin-right: 2%;
}
div.wpwl-sup-wrapper-postcode {
  float: left;
  width: 32%;
}
div.wpwl-sup-wrapper-country {
  width: 66%;
}
div.wpwl-wrapper-brand,
div.wpwl-label-brand,
div.wpwl-brand {
  display: none;
}
div.wpwl-group-cardNumber {
  float: left;
  width: 60%;
  font-size: 20px;
}
div.wpwl-group-brand {
  float: left;
  width: 35%;
  margin-top: 28px;
}
div.wpwl-brand-card {
  width: 65px;
}
div.wpwl-brand-custom {
  margin: 0px 5px;
  background-image: url('https://test.oppwa.com/v1/paymentWidgets/img/brand.png');
} */
