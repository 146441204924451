body,
html {
  width: 100%;
  margin: 0;
  overflow: auto;
  color: $content-color;
  background: #fff !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
}

button {
  font-family: $font-family-sans-serif;
}

h1,
.h1 {
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
}
h6,
.h6 {
  font-size: $h6-font-size;
}

h4 {
  .vs-tooltip & {
    color: $white;
  }
}

a:active,
a:visited,
a:hover,
a {
  color: rgba(var(--vs-primary), 1);
}

// quill editor
u {
  text-decoration: underline;
}
