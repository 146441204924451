/* =========================================================================================
    File Name: _footer.scss
    Description: Footer styles
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

.footer-sticky {
  .the-footer {
    position: fixed;
    bottom: 0;
    z-index: 40000;
    width: calc(100% - 80px);
    background: $white;
    box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.05);
  }

  .content-area-lg {
    .the-footer {
      width: calc(100% - 80px);
    }
  }

  .content-area-full {
    .the-footer {
      width: 100%;
    }
  }

  .router-view {
    padding-bottom: 5rem;
  }
}

.footer-hidden {
  .the-footer {
    display: none;
  }
}

.the-footer {
  padding: 1rem 2.2rem;
}
