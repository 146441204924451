// Core variables and mixins
// @import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '@/styles/variables/variables.scss';

$vt-toast-min-height: unset;
$vt-toast-max-height: unset;
$vt-toast-max-width: 400px;

$vt-color-default: $white;
$vt-color-info: $white;
$vt-color-success: $white;
$vt-color-warning: $white;
$vt-color-error: $white;

$vt-text-color-default: $primary;
$vt-text-color-success: $success;
$vt-text-color-warning: $warning;
$vt-text-color-error: $danger;
$vt-font-family: inherit;

// Import the regular Vue Toastification stylesheets (or create your own)
@import 'vue-toastification/src/scss/_variables';
@import 'vue-toastification/src/scss/_toast';
@import 'vue-toastification/src/scss/_closeButton';
@import 'vue-toastification/src/scss/_progressBar';
@import 'vue-toastification/src/scss/_icon';
@import 'vue-toastification/src/scss/animations/fade';

.#{$vt-namespace}__container {
  z-index: $vt-z-index;
  position: fixed;
  padding: 4px;
  width: $vt-toast-max-width;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  color: #fff;
  flex-direction: column;
  pointer-events: none;

  @media #{$vt-not-mobile} {
    &.top-left,
    &.top-right,
    &.top-center {
      top: 1em;
    }

    &.bottom-left,
    &.bottom-right,
    &.bottom-center {
      bottom: 1em;
      flex-direction: column-reverse;
    }

    &.top-left,
    &.bottom-left {
      left: 1em;
      .#{$vt-namespace}__toast {
        margin-right: auto;
      }
      // Firefox does not apply rtl rules to containers and margins, it appears.
      // See https://github.com/Maronato/vue-toastification/issues/179
      @supports not (-moz-appearance: none) {
        .#{$vt-namespace}__toast--rtl {
          margin-right: unset;
          margin-left: auto;
        }
      }
    }

    &.top-right,
    &.bottom-right {
      right: 1em;
      .#{$vt-namespace}__toast {
        margin-left: auto;
      }
      // Firefox does not apply rtl rules to containers and margins, it appears.
      // See https://github.com/Maronato/vue-toastification/issues/179
      @supports not (-moz-appearance: none) {
        .#{$vt-namespace}__toast--rtl {
          margin-left: unset;
          margin-right: auto;
        }
      }
    }

    &.top-center,
    &.bottom-center {
      left: 50%;
      margin-left: (400 / 2);
      .#{$vt-namespace}__toast {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media #{$vt-mobile} {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
    .#{$vt-namespace}__toast {
      width: 100%;
    }
    &.top-left,
    &.top-right,
    &.top-center {
      top: 0;
    }
    &.bottom-left,
    &.bottom-right,
    &.bottom-center {
      bottom: 0;
      flex-direction: column-reverse;
    }
  }
}

.#{$vt-namespace}__toast {
  padding: 1rem;
  border-radius: $card-border-radius;
  box-shadow: $box-shadow;
}

@media only screen and (max-width: 600px) {
  .Vue-Toastification__container {
    .Vue-Toastification__toast {
      width: 90%;
    }
  }
}

// ------------Dark Layout -----------//
.dark {
  .#{$vt-namespace}__toast {
    background-color: $theme-dark-card-bg;
  }
}
