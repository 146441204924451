/* =========================================================================================
    File Name: vxCard.scss
    Description: Styles for vx-card component. Imported in VxCard.vue file
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

.vx-card {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;

  &.no-shadow {
    box-shadow: none;
  }
  &.card-border {
    border: 1px solid #e4e4e4;
  }

  .vx-card__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0;
    .vx-card__title {
      h4 + h6 {
        margin-top: 0.3rem;
      }

      h6 {
        font-weight: 400;
      }
    }
    .vx-card__actions {
      .vx-card__action-buttons {
        display: flex;

        & .feather-icon {
          margin-left: 0.5rem;
        }
      }
      svg {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        transition: all 0.25s ease-out;
      }
      .rotate180 > svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  img.card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .vx-card__collapsible-content {
    transition: all 0.3s ease-in-out;

    &.vs-con-loading__container {
      overflow: unset;
    }

    &.collapsed {
      transform: scale(0.99);
      opacity: 0;
    }

    .card-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1.25rem;

      > * {
        position: relative;
        z-index: 1;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background: rgba(0, 0, 0, 0.6);
        content: '';
      }
    }

    .vx-card__body {
      //   padding: 1.5rem;

      img + .vx-card__title > h4 {
        margin-top: 1.5rem;
      }
    }
    img {
      display: block;
    }
  }

  .vx-card__code-container {
    overflow: hidden;
    transition: transform 0.35s, opacity 0.15s, max-height 0.3s ease-out;

    &.collapsed {
      transform: translateY(100%);
      opacity: 0;
    }

    .code-content {
      margin: 1.5rem;

      pre[class^='language-'] {
        max-height: 350px;
        margin-bottom: 0;
        border-radius: 0.5rem;
      }
    }
  }

  .vx-card__footer {
    padding: 0 1.5rem 1.5rem;
  }
}
