<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import { guestModule } from '../../../store/modules/guest.module';

@Component({
  components: {
    Datepicker,
  },
})
export default class DobPicker extends Vue {
  @Prop() context: Record<string, any>;

  emitSelected(val: any) {
    const dobValidation = document.querySelector('.validation') as any;
    dobValidation.innerHTML = '';
    guestModule.setDobValid(val);
  }

  get model() {
    return this.context.model;
  }
  disabledDates = { to: new Date(1930, 0, 5), from: new Date() };
}
</script>

<template>
  <div>
    <datepicker
      v-model="context.model"
      :disabled-dates="disabledDates"
      placeholder="Date of Birth"
      initial-view="year"
      class="dob-picker"
      @selected="emitSelected"
    />
    <p class="validation"></p>
  </div>
</template>

<style scoped>
.validation {
  margin-top: 13px;
  margin-bottom: 0.25em;
  color: #960505;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 1.5;
}
</style>
