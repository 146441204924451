/* =========================================================================================
    File Name: _tailwindFixes.scss
    Description: partial- Tailwind Fixes
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

// button fix
button:focus {
  outline: none;
}
