html {
  width: 100%;
  height: 100%;
  font-size: $font-size-base;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

body {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  color: white;
  font-weight: 400;
  font-family: $font-family-sans-serif;
  background: $theme-background;
  background-color: white;
  transition: background-color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

*::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 10px;
  background: $grey-light;
}
*::-webkit-scrollbar-thumb {
  // background: darken(#f5f7fb, 15%);
  // background: darken(#f5f7fb, 15%);
  background: $grey;
  border-radius: 10px;
}

#app {
  min-height: 100%;
}

.vx-logo {
  .vx-logo-text {
    font-weight: 700;
    font-size: 22px;
  }
}

.router-view {
  position: relative;
  padding: 2.2rem;

  @media (max-width: 576px) {
    padding: 1.2rem !important;

    .footer-sticky & {
      padding-bottom: 5rem !important;
    }
  }

  .content-area__heading {
    h2 {
      color: #636363;
    }
  }
}

#content-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.7s;

  .show-overlay & {
    z-index: 41001;
    opacity: 1;
  }
}

.the-footer {
  display: flex;
  align-items: center;
}

// ======================================================================================
// Content Area
// ======================================================================================
.content-wrapper {
  min-height: calc(var(--vh, 1vh) * 100 - 15rem);

  .navbar-static & {
    min-height: calc(var(--vh, 1vh) * 100 - 8rem);
  }
}
#content-area {
  height: 100%;
  transition: margin-left 0.5s;

  &.content-area-reduced {
    margin-left: 260px;
  }

  &.content-area-lg {
    margin-left: $reduced-sidebar-width;
  }

  &.content-area-full {
    margin-left: 0;
  }
}

.vs-dialog {
  margin: auto;
}
// ======================================================================================
// NavBar
// ======================================================================================

.navbar-floating {
  .router-content {
    @media (max-width: 576px) {
      margin-top: 6rem;
    }
  }
}

// ======================================================================================
// Other
// ======================================================================================

div[id$='demo'] {
  .vx-card:not(:last-of-type) {
    margin-bottom: 2.2rem;
  }
}

.vue-back-to-top {
  z-index: 51000 !important;
}

// ======================================================================================
// Component Demo
// ======================================================================================

.demo-alignment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin-top: 1.5rem;
    margin-right: 1.5rem;
  }
}

.op-block {
  padding: 10px;
  border-radius: 10px;
  // background: rgb(235, 235, 235);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

// ======================================================================================
// Applications Page
// ======================================================================================

.no-scroll-content {
  height: calc(var(--vh, 1vh) * 100 - 11.5rem);
}
