/* =========================================================================================
    File Name: vxList.scss
    Description: Styles for vx-list component. Imported in VxList.vue file
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

.list {
  .list__item {
    display: flex;
    align-items: flex-start;
    padding: 0.4rem;
  }
}
