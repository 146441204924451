<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class MarketingHeader extends Vue {
  @Prop() content: any;

  showMobileMenu = false;

  showDropdown = false;

  async signIn(state: string) {
    await this.$msal.signIn({ state });
  }
}
</script>

<template>
  <section>
    <!-- DESKTOP MENU -->
    <div class="container px-4 max-w-full border-b-2">
      <nav class="flex justify-between items-center py-3 mx-auto max-w-7xl border-gray-100" aria-labelledby="mainnavheading">
        <router-link to="/" @click="showDropdown = false"><img src="@/assets/images/logo/logo.png" /></router-link>
        <div class="lg:hidden">
          <button
            class="
              navbar-burger
              flex
              items-center
              py-2
              px-3
              text-red-600
              hover:text-red-700
              rounded
              border border-red-200
              hover:border-red-300
            "
            @click="showMobileMenu = !showMobileMenu"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>

        <ul class="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
          <!-- <div class="dropdown relative">
           
            <button
              type="button"
              @click="showDropdown = !showDropdown"
              class="text-sm text-gray-500 hover:text-gray-600 group bg-white rounded-md inline-flex items-center"
              aria-expanded="false"
            >
              <span>Product</span>
              <svg
                class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <div
              v-if="showDropdown"
              class="dropdown-menu absolute z-50 left-1/3 transform -translate-x-1/3 mt-3 px-2 w-screen max-w-xs sm:px-0"
            >
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  <li @click="showDropdown = false"
                    ><router-link :to="$t('content.navBar.navBarItems[0].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
                      $t('content.navBar.navBarItems[0].fields.title')
                    }}</router-link></li
                  >

                  <li @click="showDropdown = false"
                    ><router-link :to="$t('content.navBar.navBarItems[1].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
                      $t('content.navBar.navBarItems[1].fields.title')
                    }}</router-link></li
                  >

                  <li @click="showDropdown = false"
                    ><router-link :to="$t('content.navBar.navBarItems[2].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
                      $t('content.navBar.navBarItems[2].fields.title')
                    }}</router-link></li
                  >
                </div>
              </div>
            </div>
          </div> -->

          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[0].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[0].fields.title')
            }}</router-link></li
          >
          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[1].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[1].fields.title')
            }}</router-link></li
          >
          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[2].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[2].fields.title')
            }}</router-link></li
          >

          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[3].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[3].fields.title')
            }}</router-link></li
          >

          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[4].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[4].fields.title')
            }}</router-link></li
          >

          <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[5].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[5].fields.title')
            }}</router-link></li
          >

          <!-- <li @click="showDropdown = false"
            ><router-link :to="$t('content.navBar.navBarItems[6].fields.url')" class="text-sm text-gray-500 hover:text-gray-600">{{
              $t('content.navBar.navBarItems[6].fields.title')
            }}</router-link></li
          > -->
        </ul>
        <div class="hidden lg:block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          ><button id="marketingHeaderSignInBtn" class="white-button-outline-red" @click="signIn('member')">{{
            $t('content.navBar.signUpsignInButtons[0].fields.labelText')
          }}</button>
          <button
            id="marketingHeaderRegisterBtn"
            class="ml-1 red-button-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click="signIn('register')"
            >{{ $t('content.navBar.signUpsignInButtons[1].fields.labelText') }}</button
          >
        </div>
      </nav>
    </div>

    <!-- MOBILE MENU -->
    <div v-if="showMobileMenu" class="navbar-menu relative z-50">
      <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" @click="showMobileMenu = false"></div>
      <nav
        class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto"
        aria-labelledby="mobilenavheading"
      >
        <div class="flex items-center mb-8">
          <a class="mr-auto text-3xl font-semibold leading-none"><logo /></a>
          <button class="navbar-close" @click="showMobileMenu = false">
            <svg
              class="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div>
          <ul>
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[0].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[0].fields.title') }}</router-link
              ></li
            >
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[1].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[1].fields.title') }}</router-link
              ></li
            >
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[2].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[2].fields.title') }}</router-link
              ></li
            >
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[3].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[3].fields.title') }}</router-link
              ></li
            >
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[4].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[4].fields.title') }}</router-link
              ></li
            >
            <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[5].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[5].fields.title') }}</router-link
              ></li
            >
            <!-- <li class="mb-1" @click="showMobileMenu = false"
              ><router-link
                :to="$t('content.navBar.navBarItems[6].fields.url')"
                class="block p-4 text-sm text-gray-500 hover:bg-red-50 hover:text-red-600"
                >{{ $t('content.navBar.navBarItems[6].fields.title') }}</router-link
              ></li
            > -->
          </ul>
          <div class="mt-4 pt-6 border-t border-gray-100"> </div>
        </div>
        <div class="mt-auto text-center">
          <button id="marketingHeaderRegisterBtn" class="w-full mb-2 red-button-sm text-center" @click="signIn('member')">{{
            $t('content.navBar.signUpsignInButtons[0].fields.labelText')
          }}</button
          ><button id="marketingHeaderSignInBtn" class="w-full white-button-outline-red text-center" @click="signIn('register')">{{
            $t('content.navBar.signUpsignInButtons[1].fields.labelText')
          }}</button>
        </div>
      </nav>
    </div>
  </section>
</template>

<style lang="css">
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
