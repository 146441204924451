// Snow theme colors
// -----------------------------------------------------------------------------

$formulate-font-stack: 'Poppins', 'Roboto' !default;

$formulate-gray: #efefef;
$formulate-gray-d: #cecece;
$formulate-gray-dd: #a8a8a8;
$formulate-gray-ddd: #6d6d6d;
$formulate-gray-dddd: #2e2e2e;

$formulate-dark: #000;

$formulate-blue-l: #f3f4f4;

$formulate-green: #c81e1e;
$formulate-green-l: #64c89b;

$formulate-red: #e02424;
$formulate-red-l: #e02424;

$formulate-error: #960505;
$formulate-error-l: #dc2c2c;

$formulate-yellow-d: #6b5900;
$formulate-yellow: #e6c000;
$formulate-yellow-l: #fff8d2;

$formulate-white: #fff;

// Mixins
// -----------------------------------------------------------------------------
@mixin baseinput {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0.75em;
  font-weight: 400;
  font-family: $formulate-font-stack;
  line-height: 1.2em;
  background-color: white;
  border: 1px solid $formulate-gray-d;
  border-radius: 0.3em;
  appearance: none;

  &::placeholder {
    color: $formulate-gray-dd;
    line-height: normal;
  }

  &:focus {
    border: 1px solid $formulate-green;
    outline: 0;
  }
}

@mixin down-arrow {
  width: 0;
  height: 0;
  border: 0.3em solid transparent;
  border-top-color: $formulate-gray-d;
  border-bottom-width: 0;
  content: '';
}
