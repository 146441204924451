@import 'tailwindcss/utilities';
$boxShadow: 2px 2px 10px #70707040;

$green: #006a6e;

.formulate-input {
  margin-bottom: 0.5em;
}
.info {
  h2 {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 20px;
  }
}
.h-0.5 {
  height: 0.125rem;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  &:hover {
    cursor: pointer;
  }
}

.schema {
  position: relative;
  box-sizing: border-box;
  padding: 1.5em 1em 1em 1em;
  //   border-top: 1px solid #eaecef;

  .flex-wrapper {
    // max-width: 40em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // flex-flow: column;

    & > .formulate-input {
      width: 45%;
      //   min-width: 45%;
      &.flex-item-small {
        flex-basis: 5em;
        flex-shrink: 0;
        width: 53em;
      }

      &:nth-child(odd) {
        margin-right: 1em;
      }
    }
  }

  @media (min-width: 500px) {
    width: auto;
    border-top: 0;
  }
  @media (min-width: 720px) {
    width: auto;
    border-top: 1px solid #eaecef;
  }

  @media (min-width: 850px) {
    width: 100%;
    border-top: 0;
  }
}
.email-sm .vs-dialog-content {
  height: 30% !important;
}

.temp-checks {
  max-height: 110px;
  overflow-y: scroll;
}

.schema-error {
  color: #d57275;
  font-size: 14px;
  font-style: italic;
}

.schema-group {
  @media (min-width: 500px) {
    display: flex;
  }

  @media (min-width: 720px) {
    display: block;
  }

  @media (min-width: 850px) {
    display: flex;
  }
}
* {
  //   margin: 0px;
  //   padding: 0px;
  box-sizing: border-box;
  text-transform: none;
  text-decoration: none;

  /* list-style: none; */
  outline: none;
}
.vs-table-content {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.vs-table__expand__td__content {
  height: 100% !important;
}
// .flex-wrapper {
//   max-width: 20em;
//   display: flex;
// }

// I'm just putting styles here for now
h3 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
}
.vs-navbar__item {
  padding: 0 15px;
  font-size: 1.1rem !important;
}
.vs-navbar__line {
  background-color: $primary;
}
.dr-card {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
}

input {
  font-family: $font-family-sans-serif;
}
.vs-dialog--header {
  color: white;
  background: #66999b !important;

  h3 {
    color: white;
  }
}

// .feather-icon {
//   color: #66999b !important;
// }
// .vs-collapse-item--content {
//   max-height: 100% !important;
// }
.vs-collapse-item--header {
  position: relative;
  background: #66999b;
  border-radius: 5px;
  h3 {
    color: white;
  }
  .vs-icon {
    color: white;
    font-size: 2rem;
  }
}

// .vs-collapse-item--header:after {
//   content: "";
//   display: block;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background-image: url(http://s14.directupload.net/images/111129/44ga9qid.png);
//   background-size: 2px 2px;
//   border-radius: 7px;
// }
.knowledge-base-jumbotron-content {
  background-color: #ea5455;
  background-size: cover;
}

.p-box {
  /* the other rules */
  transition: box-shadow 0.3s;
}
.p-box:hover {
  box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, 0.5);
}
div[id^='__lpform_'] {
  display: none;
}
.card-save {
  padding: 20px;
}
.vs-collapse {
  padding: 0;
}

.list-wrp li {
  display: inline;
}

.checkboxes {
  display: inline;
}

// .booking-form {
//   .input-field {
//     margin-top: 0px;
//   }
// }

.item--header {
  position: relative;
}

.list-wrp {
  display: flex;
}

.con-vs-checkbox {
  min-width: 125px;
  margin-bottom: 5px;
  margin-left: 0;
}
// .con-select {
//   width: 100%;
// }

.check-boxes {
  padding: 0 15px;
}
.vs-navbar-content {
  border-radius: 0 !important;
}

#padding-scroll-content {
  position: relative;
}
.button {
  display: flex;
  padding: 0.3rem 2rem !important;
}

.terms-modal-header {
  padding: 1.5rem;
  padding-bottom: 0;
}

.terms-modal {
  h2 {
    margin: 10px 0px;
  }
  h4 {
    margin-bottom: 10px;
  }
  h6 {
    padding: 10px 0px;
  }
  p {
    margin-top: 15px;
  }
}

.pdf-buttons {
  display: flex;
  padding: 10px !important;
  text-align: center;
  border-top: 1px solid $green;
  border-bottom: 1px solid $green;
  transition: all 0.2s;
  &:hover {
    background: $green;
    cursor: pointer;
    h4 {
      color: white;
    }
  }
  .feather {
    color: #ccc;
  }
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    transition: all 0.2s;
  }
}

.secondary {
  background-color: $secondary !important;
}

.wrp {
  display: inline-flex;
  float: right;
}

.vs-con-table .vs-con-tbody {
  margin-top: 20px;
}

.vs-table--thead tr {
  color: lightslategray;
  background: none !important;
}

.btn-wrp {
  padding: 20px;
  & > div {
    margin: auto;
    padding-right: 15px;
  }
  & > span {
    position: absolute;
    top: -9px;
    right: -8px;
    width: 25px;
    height: 29px;
    padding: 4px 8px;
    background: white;
    border-radius: 21px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    &:hover {
      cursor: pointer;
    }
  }
}
.guest-profile-wrp {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  background: white;
  border-radius: 10px;
  // padding: 20px;
  box-shadow: $boxShadow;
  span {
    margin-top: 15px;
    margin-left: 20px;
    // position: absolute;
  }
  h3 {
    margin-bottom: 10px;
    font-weight: 500;
  }
  h4 {
    font-size: 16px;
  }
  .info {
    margin-top: -46px;
    h4 {
      margin: 10px 0;
      margin-bottom: 0;
      color: #008489;
    }
    p {
      margin-top: 5px;
    }
    .button {
      margin: auto;
      color: white;
      background-color: #008489;
    }
  }

  .extra-info {
    display: flex;
    margin-top: 25px;
    padding: 20px;
    color: white;
    background: #008489;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0 15px 15px;
    li {
      flex: 1;
      padding: 0 5px;
      place-self: center;
    }
    li:first-of-type {
      border-right: 1px solid white;
    }
    h3 {
      max-width: 120px;
      margin: auto;
      font-weight: 400;
      font-size: 17px;
    }
    h4 {
      margin: 20px 0;
      font-size: 20px;
    }
  }
  h2 {
    font-size: 20px;
  }
}

.AddBookingCard,
.booking-card-main {
  .header {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #047481;
    border-radius: 5px 5px 0 0;
    h3,
    h4,
    h5 {
      margin: 0;
      color: white;
    }
    span {
      color: white;
    }
  }
  .inner-card {
    padding: 15px;
    padding-top: 0;
    h4 {
      margin: 0;
      font-size: 1rem;
    }
  }
}
// table tr:nth-child(odd) {
//   background-color: #efebeb;
// }

.guestInfo-wrp {
  padding: 1.25rem;
}

.vs-table {
  .vs-input-parent {
    margin: 15px 0;
  }
}
.AddBookingCard .inputs {
  .vs-input-parent {
    margin: 1rem !important;
  }
}

.booking-details {
  padding: 25px;
  background-color: white;
  div {
    margin: auto;
  }
}

.minor-table {
  .vuesax-app-is-ltr .vs-input--input.hasValue + .vs-placeholder-label {
    transform: translate(-3px, -65%) !important;
    .vs-table--tbody-table .tr-values td {
      padding: 2px 15px;
    }
    .vs-table--tbody-table tr {
      background: white;
    }
  }
}

.wide-popup {
  .vs-dialog {
    width: 800px;
  }
}

// .vs-dialog {
//   min-width: 50%;
// }

.pdf-controls {
  display: flex;
}

.arrows:hover {
  cursor: pointer;
}

.questions .formulate-input-element {
  display: none !important;
}

.filter-modal {
  position: relative;
  z-index: 1;
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.vs-select__label--label {
  font-size: 1rem;
}

.slide-fade-enter-active {
  z-index: 999;
}

.temperature-modal {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: visible !important;
    transform: translate(-50%, -50%) !important;
  }
}

.booking-inner-table {
  display: flex;
  div {
    padding: 5px 15px;
  }
}

.the-footer {
  bottom: 0;
  width: 100%;
}

.layout--main {
  position: relative;
  height: 100%;
  padding-bottom: 1.5rem;
  background-color: #fff;
}

.vs-checkbox-con {
  min-width: 23px;
}

input[disabled='disabled'] {
  background-color: #eee !important;
  cursor: not-allowed;
  opacity: 1;
}

div[disabled] {
  background-color: #eee !important;
}

.vs-divider {
  color: #66999b;
}

.menu-control-wrp {
  .nav-dropdown {
    position: absolute;
    right: 0;
    z-index: 9;
    min-width: 250px;
    margin-top: 10px;
    padding: 8px 0px;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 10px #707070;

    li {
      padding: 12px 16px;
      &:hover {
        background: #f3f3f3;
      }
    }
  }
  .menu-control {
    display: flex;
    padding: 5px 15px;
    padding-right: 5px;
    border: 1px solid #ddd;
    border-radius: 30px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      color: white;
      background: #047481;
      border-radius: 50%;
    }
    .fa-bars {
      margin-right: 0.8rem;
    }
  }
}

.con-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-header {
  position: relative;
  display: flex;
  height: 11rem;
  background-color: #008489;
  border-radius: 25px 25px 0 0;
  .vs-avatar-content {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    transform: translate(-50%, 62px);
  }
}

.card-wrp {
  .vs-input-content {
    margin: 10px 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .mobile {
    display: block !important;
  }
  .guest-profile-wrp {
    margin-bottom: 40px;
  }
  .card-wrp {
    flex: 1 0 40% !important;
  }
}
.vue-form-wizard {
  .wizard-header {
    color: white;
    background: #0694a2;
    border-radius: 0 !important;
  }
  .category {
    color: white;
  }
}
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #0af;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
.sign-in-wrapper {
  min-height: 642.328px;
}
.l-image {
  img {
    display: none;
  }
}

.Card__face {
  position: relative;
}

.Card__center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  font-size: 32pt;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.email-verification .vs-dialog {
  max-width: 500px;
}
.Card-flip {
  width: 30% !important;
}

.badger-accordion-toggle {
  margin-top: 0;
  padding: 14px 15px;
}

.formulate-input-wrapper {
  margin-bottom: 10px;
}

.vs-alert {
  margin-top: 20px;
}

.accommodation-wrp {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 320;
  margin: auto;
  border-radius: 25px;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.5);
}

.no-data-container {
  margin: auto;
  padding: 25px 0;
}

.accommodation-header {
  position: relative;
  height: 11rem;
  background: #008489;
  border-radius: 25px 25px 0 0;
  h2 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin-bottom: 0;
    color: white;
  }
}
.accommodation-body {
  display: flex;
  width: 100%;
  padding: 0 25px;
  padding-bottom: 25px;
  span {
    flex: 1 auto;
    justify-content: space-between;
  }
  h3 {
    margin-top: 10px;
    color: #008489;
    font-weight: 500;
    font-size: 18px;
  }
}

.ordered-list {
  dl {
    display: flex;
    flex-wrap: wrap;
  }
  dt {
    width: 50%;
    margin: 5px 0;
    color: #707070;
  }
  dd {
    width: 50%;
    margin: 5px 0;
    color: #707070;
  }
}

.booking-container {
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: $boxShadow;
}

.grey-btn {
  display: block;
  width: 100%;
  height: 32px;
  margin-top: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #00000029;
}

.guest-img {
  width: 220px;
  height: 220px;
  margin: auto;
  margin-top: 15px;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #00000029;
}

.profiled-asd {
  width: 220px;
  height: 220px;
  margin: auto;
  margin-top: 15px;
  background-size: cover;
  border-radius: 50%;
}

.yellow-btn {
  display: block;
  width: 100%;
  height: 32px;
  margin-top: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #00000029;
}

.dark-asd {
  display: block;
  width: 100%;
  height: 32px;
}

.formulate-input[data-classification='box'] {
  .formulate-input-element {
    min-width: 20px;
  }
}

.guest-info {
  margin: auto;
  padding-bottom: 30px;
}

.covid-status {
  padding: 3px 8px;
  font-weight: 500;
  border-radius: 10px;
}

.blurb {
  margin-bottom: 25px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: $boxShadow;
}

.zed {
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: $boxShadow;
}

svg {
  display: inline-block;
}

.fullscreen-popup {
  .vs-dialog {
    width: 95%;
    max-width: 1400px;
    margin-top: 20px;
  }
  h2 {
    color: #6b7280;
    font-weight: 300;
    font-size: 20px;
  }
}

.doc-wrp {
  min-height: 372px;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: $boxShadow;
  h3 {
    width: 100%;
    padding: 15px;
    color: #6b7280;
    font-weight: normal;
    font-size: 14px;
    border-bottom: 1px solid #6b72807a;
  }
}

.doc-list {
  a {
    color: #6b7280;
  }
  div {
    padding: 7px 15px;
    border-bottom: 1px solid #6b72807a;
    transition: all 0.2s;
    svg {
      margin-right: 15px;
      color: #6b7280;
      font-size: 30px;
    }

    &:hover {
      background: #f5f2f2;
      cursor: pointer;
    }
  }
}

.tempCheck {
  input,
  textarea {
    border: 1px solid #989696 !important;
  }
  [class^='asd__'] {
    @apply dark:bg-gray-500 dark:text-white;
  }
  .formulate-input-label {
    color: #848080;
    font-weight: 400;
  }
  .asd__day-title {
    position: relative;
    z-index: 101;
    @apply dark:text-white;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #6b72807a;
}

.modal-wrp {
  padding: 25px;
  .guest-info-wrp {
    padding: 12px;
    border-radius: 10px;
    box-shadow: $boxShadow;
    dd {
      box-shadow: none;
    }
    h3 {
      margin-bottom: 15px;
      font-weight: 400;
    }
  }
}

.add-booking-form {
  .formulate-input .formulate-input-label,
  .input-label {
    color: #8a8686;
    font-weight: 400;
    font-size: 0.9em;
    @apply dark:text-white;
  }
  [class^='asd__'] {
    @apply dark:bg-gray-500 dark:text-white;
  }
  .asd__wrapper {
    bottom: unset;
    .full-screen {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .asd__day-title {
    position: relative;
    z-index: 101;
    @apply dark:text-white;
  }
  .asd__day,
  .asd__day--empty,
  .asd__day-button,
  .asd__action-buttons {
    @apply dark:bg-gray-500 dark:text-white;
  }
  .asd__keyboard-shortcuts-menu {
    display: none;
    border-right: 33px solid transparent;
  }
  .formulate-input-wrapper {
    margin-bottom: 18px;
  }
  h3 {
    font-weight: 400;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.vue-country-select {
  height: 35px;
  margin-top: 3px;
  margin-right: 15px;
  margin-bottom: auto;
  padding: 0em;
}

.filtered {
  div {
    margin-right: 15px;
    input {
      margin-right: 5px;
      cursor: pointer;
    }
    [type='checkbox'] {
      color: $primary;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    label {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.booking-counters {
  display: flex;
  margin-bottom: 25px;
  div {
    margin-right: 2em;
  }
  .label {
    color: #8a8686;
    font-weight: 400;
    span {
      margin-left: 0.5rem;
      color: #d8d5d5;
      font-size: 0.9rem;
    }
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}

.datepicker .input-wrapper {
  border: none !important;
}

@media only screen and (max-width: 1150px) {
  .guest-img {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 760px) {
  .card-wrp {
    flex: 1 0 48% !important;
  }

  .Card-flip {
    width: 55% !important;
  }
}

@media only screen and (max-width: 501px) {
  .card-wrp {
    flex: 1 0 88% !important;
  }

  .Card-flip {
    width: 90% !important;
  }
}
