<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTimepicker from '@/app/components/forms/timepicker/VueTimepickerPlus.vue';

@Component({
  components: { VueTimepicker },
})
export default class Timepicker extends Vue {
  @Prop() context: Record<string, any>;

  log(val: any) {
    this.context.model = val;
    /*     console.log(val);
     */
  }
}
</script>

<template>
  <div>
    <div class="label mb-2" :class="`formulate-input-element formulate-input-element--${context.type}`"
      >{{ context.attributes.head }}
      <span v-if="context.attributes.sub">{{ context.attributes.sub }} </span>
    </div>
    <vue-timepicker
      v-model="context.model"
      input-width="12em"
      auto-scroll
      :hour-range="[12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
      :minute-interval="15"
      keep-range-format
      drop-direction="up"
      close-on-complete
    ></vue-timepicker>
  </div>
</template>
<style>
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #ea5455;
  color: #fff;
}
</style>
