/* =========================================================================================
  File Name: _fixesVuesax.scss
  Description: Partial - Fixes/Add vuesax framework styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

// ======================================================================================
// Alert
// ======================================================================================

// Alert shadow and color issue
.con-vs-alert {
  box-shadow: none !important;
}

.vs-alert {
  font-weight: 500;
  font-size: 1rem;

  code {
    color: $white;
    background: #b5b5b5;
  }
}

// .vs-alert {
//   code.language-markup {
//     background: transparent !important;
//     color: #636363 !important;
//   }
// }

.vs-alert--title {
  color: inherit;
}

// ======================================================================================
// Avatar
// ======================================================================================

.vs-avatar--text.feather {
  font-size: 1.3rem;
}

.vs-avatar--con-img {
  img {
    height: 100%;
  }
}

// ======================================================================================
// Button
// ======================================================================================

.button {
  font-weight: 600;
  font-size: 1rem;

  &.button-gradient {
    &:hover {
      box-shadow: none !important;
    }
  }

  &:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    padding: 0.75rem 2rem;

    &.button-border {
      padding: 0.679rem 2rem;
    }
  }

  &.small:not(.includeIconOnly) {
    padding: 0.5rem 1.5rem;
  }

  &.large:not(.includeIconOnly) {
    padding: 1rem 2.5rem;
  }

  &.large {
    font-size: 1.25rem;

    .button--icon {
      font-size: 1.25rem;
    }
  }

  &.round {
    border-radius: 1.5rem;
  }

  &.includeIcon {
    float: none;
  }
}

// ======================================================================================
// Breadcrumb
// ======================================================================================

.vs-breadcrumb--ol {
  a {
    &:focus,
    &:hover {
      color: $primary;
    }
  }

  .active {
    color: $primary;
  }
}

// ======================================================================================
// Check-box
// ======================================================================================

.vs-checkbox-small {
  .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
    margin-top: 6px;
    margin-left: -1px;
    transform: translateY(-3px);
  }
}

.vs-checkbox--check {
  display: flex;
  align-items: center;
  justify-content: center;
}

// ======================================================================================
// Chip
// ======================================================================================

.con-chips {
  .con-chips--input {
    border: none;
  }

  .con-vs-chip {
    margin: 0.75rem;
  }

  .con-chips--remove-all {
    right: 9px;

    > .vs-icon {
      font-size: 1.3rem;
    }
  }
}

.con-vs-chip {
  min-width: 26px;
  min-height: 26px;
  font-size: 0.8rem;
}

// ======================================================================================
// Collapse
// ======================================================================================

.vs-collapse-item--header {
  padding: 1rem;
  font-size: 1.2rem;
}

.con-content--item {
  padding: 1rem;
  font-size: 1rem;
}

.vs-collapse.default,
.vs-collapse.shadow,
.vs-collapse.border,
.vs-collapse.margin {
  .open-item {
    .con-content--item {
      padding: 1rem;
      opacity: 1;
    }
  }
}

// ======================================================================================
// Dialog
// ======================================================================================

.con-vs-dialog {
  z-index: 53000;

  .vs-dialog {
    width: 50%;
    header {
      .dialog-title {
        padding: 0.8rem;
        color: inherit;
      }

      span.after {
        width: 0;
      }
    }

    .vs-dialog-text {
      padding: 1rem;
      font-size: 1rem;
    }

    footer {
      padding: 1rem;

      .button:last-of-type {
        margin-left: 0.5rem !important;
        border-color: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
  .vs-dialog--content {
    padding: 1rem;
    font-size: 1rem;
  }
}

// ======================================================================================
// DropDown
// ======================================================================================

.vs-con-dropdown {
  color: inherit;
  font-size: 1rem;
}

// Dropdown Button padding
.dropdown-button-container {
  .button {
    padding: 0.72rem 1.5rem !important;
  }

  .button-line {
    padding: 9px 10px !important;
  }
}

.con-vs-dropdown--menu {
  z-index: 42000;
}

// ======================================================================================
// Input
// ======================================================================================

.vs-input--placeholder {
  top: 0;

  &.normal {
    padding: 0.7rem !important;
  }

  &.large {
    padding: 1rem !important;
  }

  &.small {
    padding: 0.2rem 0.6rem !important;
    font-size: 0.8rem !important;
  }
}

.vs-input--input.hasIcon:not(.icon-after-input) {
  & + .vs-input--placeholder {
    padding-left: 3rem !important;
  }

  &:focus + .vs-placeholder-label {
    padding-left: 0.7rem !important;
  }
}

// Search Box Text
// .vs-input--input.large.hasIcon {
//   padding: 1rem .8rem 1rem 3rem;
// }

// INPUT FORM ELEMENT COMPONENT
.vs-con-input {
  .vs-inputx {
    padding: 0.7rem;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .vx-inputx:not(.input-rounded-full) {
    border-radius: 5px;
  }
}

.vs-input--input {
  &.normal {
    padding: 0.7rem !important;
    font-size: 1rem !important;

    ~ .vs-input--icon {
      &.feather {
        top: 9px;
      }
    }
  }

  &.large {
    padding: 1rem !important;
    font-size: 1.2rem !important;

    &.hasIcon {
      padding: 1rem 0.8rem 1rem 3rem !important;
    }

    ~ .vs-input--icon {
      top: 1rem !important;
      left: 0.8rem !important;
    }
  }

  &.small {
    padding: 0.4rem !important;
    font-size: 0.8rem !important;

    ~ .vs-input--icon {
      &.feather {
        top: 7px !important;
      }
    }
  }

  &.hasIcon {
    padding: 0.7rem 1rem 0.7rem 3rem !important;

    &.icon-after-input {
      padding: 0.7rem 3rem 0.7rem 0.7rem !important;
    }
  }
}

.vs-input--icon {
  &.feather {
    // top: 10px;
    padding: 0.2rem 0.5rem 0 0.4rem;
  }
}

// Rounded Input
.vs-input.input-rounded-full {
  // Fixes input element don't get rounded borders
  input {
    border-radius: 20px;
  }

  .vs-input--input.hasIcon {
    padding: 0.8rem 1rem 0.8rem 3rem !important;
  }

  .input-span-placeholder {
    padding-top: 0.7rem !important;
    padding-left: 3rem !important;
  }

  .vs-icon {
    margin-top: 0.1rem !important;
    margin-left: 0.6rem !important;
    font-size: 1rem !important;
  }
}

// ======================================================================================
// List
// ======================================================================================

.vs-list--item {
  .list-titles {
    .vs-list--subtitle {
      font-size: 0.85rem;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

// ======================================================================================
// NavBar
// ======================================================================================

.vs-navbar--item {
  a {
    .vs-navbar & {
      color: inherit;
    }

    .vs-navbar-color-transparent &:hover {
      color: $primary !important;
    }
  }

  .vs-navbar-gradient & {
    &.is-active-item a {
      color: #fff !important;
    }
  }
}

// ======================================================================================
// Notification
// ======================================================================================

.vs-notifications {
  z-index: 200000 !important;

  h3 {
    color: $white;
    font-weight: 600;
    font-size: $h5-font-size;
  }
}

// ======================================================================================
// Pagination
// ======================================================================================
.vs-pagination--li.is-current {
  border-radius: 50%;

  .effect {
    border-radius: 50%;
  }
}

.vs-pagination--li {
  &:hover {
    &:not(.is-current) {
      color: var(--vs-color-pagination) !important;
    }
  }
}

.vs-pagination--ul {
  padding: 0;
}

// ======================================================================================
// PopUp
// ======================================================================================

.vs-dialog--title,
.vs-notifications {
  h3 {
    margin-bottom: 0;
  }
}

// ======================================================================================
// Radio
// ======================================================================================

// Fixes check-box gets in center
.con-vs-checkbox,
.con-vs-radio {
  justify-content: flex-start !important;
}

.vs-radio--label {
  line-height: 1;
}

.con-vs-radio {
  display: inline-flex;
}

// ======================================================================================
// Select
// ======================================================================================

.con-select {
  .vs-select--input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.vs-select--options {
  z-index: 530001;
  font-size: 1rem;
  border: 1px solid #eee;

  span {
    color: $content-color;
    font-size: 1rem;
  }
}

.v-select {
  .dropdown-toggle {
    .vs__actions {
      .clear {
        padding-top: 4px;
      }
    }
  }
}

// ======================================================================================
// Sidebar
// ======================================================================================

.vs-sidebar {
  height: calc(var(--vh, 1vh) * 100);

  .vs-sidebar--items {
    height: 100%;
    overflow: hidden;
  }
}

// ======================================================================================
// Switch
// ======================================================================================

.vs-switch--text {
  font-size: 0.7rem;
}

// ======================================================================================
// Table
// ======================================================================================
.vs-con-table {
  background: transparent;

  .vs-table--header {
    .vs-table--search {
      padding: 1rem 0;

      // align text in search input
      .vs-table--search-input {
        padding: 10px 28px;
        font-size: 0.9rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      .vs-table--search-input:focus + i {
        left: 10px;
      }

      // align search icon in table
      i {
        left: 10px;
      }
    }
  }

  .vs-con-tbody {
    width: 100%;
    background: #f8f8f8;
    border: 2px solid #f8f8f8;

    // Justify checkboxe center
    .con-vs-checkbox {
      justify-content: center !important;
    }

    .vs-table--tbody-table {
      font-size: 1rem;

      .tr-spacer {
        height: 2px;
      }

      .tr-table {
        .tr-expand {
          td {
            padding: 0;
          }
        }

        td {
          padding: 1rem;
        }
      }

      .vs-table--thead {
        th {
          padding: 10px 15px;
        }

        .con-td-check {
          background: transparent;
          box-shadow: none;
        }
      }

      .tr-values {
        .vs-table--td {
          padding: 10px 15px;
        }
      }
    }
  }

  .vs-table--pagination {
    margin-top: 1rem;
  }
}

// ======================================================================================
// Tabs
// ======================================================================================

.con-slot-tabs {
  width: 100%;
}

// Remove after merge of: https://github.com/lusaxweb/vuesax/pull/637
.vs-tabs--li {
  white-space: nowrap;

  .vs-tabs-position-left & {
    padding: 0.35rem 0.3rem;
  }
  button {
    color: inherit;
    font-weight: 700;
    font-weight: 500;
    font-size: 1rem;
    font-family: inherit;
  }

  .vs-icon-primary,
  .vs-icon-success,
  .vs-icon-warning,
  .vs-icon-danger,
  .vs-icon-dark {
    color: inherit;
  }
}

// Remove after merge of: https://github.com/lusaxweb/vuesax/pull/637
.vs-tabs {
  .con-tab {
    .button-filled {
      &:hover {
        color: #fff !important;
      }
    }
  }
}

// ======================================================================================
// Text-area
// ======================================================================================

.vs-textarea {
  color: inherit;
  font-size: 1rem;
  font-family: $font-family-sans-serif;
  line-height: 1.6;
}

.vs-con-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;

  > h4 {
    color: inherit;
  }
}

// ======================================================================================
// Tool-tip
// ======================================================================================

.vs-tooltip {
  z-index: 52000;
}

// ======================================================================================
// Upload
// ======================================================================================

.con-img-upload {
  padding: 0.6rem;
  overflow: hidden;

  .img-upload {
    margin: 15px;
  }
}

.view-upload {
  z-index: 52000;
}
