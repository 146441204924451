/* =========================================================================================
    File Name: _misc.scss
    Description: partial- misc styles
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

code {
  padding: 0.1rem 0.3rem;
  font-family: $font-family-sans-serif;
  background: #eee;
  border-radius: 3px;
}
.vs-input-content {
  background-color: #f4f7f8;
}
ul,
ol {
  margin: 0;
  padding: 0;
}

.tiny-wrapper {
  text-align: left !important;
  ul,
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
.vs-dialog-content {
  z-index: 9999 !important;
  height: 100%;
  margin: auto;
  padding-top: 0;

  .vs-dialog {
    // min-width: 45%;
    // height: 75%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(198, 190, 207);
    border-radius: 4px;
    box-shadow: rgba(37, 11, 54, 0.04) 0 2px 0;
  }
}

.vs-dialog__content.notFooter {
  height: 100%;
}
.layout--full-page {
  height: 100%;
  .bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .full-page-bg-color {
    background-color: #eff2f7;
  }
}

.single-counter {
  position: relative;
  display: inline-block;
  width: 105px;
  padding: 18px 10px 10px;

  span {
    display: block;
    text-align: center;
  }

  .timer {
    font-size: 3rem;
  }
}

.chat-card-log {
  height: 240px;
}

// ======================================================================================
// Video.js
// ======================================================================================
.vjs-poster {
  width: 100% !important;
  background-size: cover !important;
}

// ======================================================================================
// Spinner
// ======================================================================================

// .spinner {
//   animation: spin 1.5s linear infinite;
// }

/*! rtl:begin:ignore */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*! rtl:end:ignore */

// ======================================================================================
// Feather - Font Icon
// ======================================================================================

i.feather {
  font-weight: 100;
}

.feather.feather-menu {
  z-index: 9;
}

// ======================================================================================
// Perfect Scrollbar
// ======================================================================================

.scroll-area {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;

  &:not(.ps) {
    overflow-y: auto;
  }

  .ps__rail-x,
  .ps__rail-y {
    position: absolute;
    display: block !important;
    background-color: $grey-light !important;
    opacity: 1 !important;
  }

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block !important;
    background-color: $grey-light !important;
  }

  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y,
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y {
    opacity: 1 !important;
  }

  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y.ps--clicking,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-y:hover {
    background-color: $grey-light !important;
    opacity: 0.7 !important;
  }

  .ps__thumb-x {
    bottom: 0 !important;
    height: 10px !important;
  }

  .ps__rail-x {
    bottom: 0 !important;
    height: 10px !important;
  }

  .ps__thumb-x,
  .ps__thumb-y {
    position: absolute !important;
    background-color: $grey !important;
    border-radius: 6px !important;
  }
  .ps__thumb-y {
    right: 0 !important;
    width: 10px !important;
  }
  .ps__rail-y {
    right: 0 !important;
    width: 10px !important;
  }
  .ps__rail-x.ps--clicking .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x {
    height: 10px !important;
    background-color: $grey !important;
  }
  .ps__rail-y.ps--clicking .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y {
    width: 10px !important;
    background-color: $grey !important;
  }
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto !important;
    }
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .ps {
      overflow: auto !important;
    }
  }
}
