$cube-phone-number-input-border-color: #ccc !default;
$cube-phone-number-input-placeholder-color: #999 !default;
$cube-phone-number-input-country-code-color: #999 !default;

.cube-phone-number-input-inline {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 4px 16px;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.5;
  background-color: white;
  border: 1px solid $cube-phone-number-input-border-color;
  border-radius: 2px;
  outline: none;
  cursor: text;
  transition: all 250ms;
  appearance: none;
  .country-code-selector {
    flex: 1 0 auto;
    box-sizing: border-box;
    max-width: 120px;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    overflow: hidden;
    color: $cube-phone-number-input-country-code-color;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: inherit;
    border: 0 none;
    border-right: 1px solid $cube-phone-number-input-border-color;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    appearance: none;
    &:invalid {
      color: $cube-phone-number-input-placeholder-color;
    }
  }
  .phone-number-input {
    flex: 1 1 auto;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    background-color: inherit;
    border: 0 none;
    outline: none;
    appearance: none;
    &::placeholder {
      color: $cube-phone-number-input-placeholder-color;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      background-color: inherit;
      opacity: 1; // Firefox
    }
    // Internet Explorer 10-11
    &:-ms-input-placeholder {
      color: $cube-phone-number-input-placeholder-color;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      background-color: inherit;
    }
    // Microsoft Edge
    &::-ms-input-placeholder {
      color: $cube-phone-number-input-placeholder-color;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      background-color: inherit;
    }
  }
}
