$black: #444;
$black-light: #999;
$black-lighter: #ccc;
$main: #c81e1e;
$main-hover: #f05252;
$border: #c2ccdc;
$shadow: rgba(0, 0, 0, 0.1);
$base-size: 10px;
$picker-bg: #fff;
$bg: $picker-bg;

@function size($val) {
  @return $val * $base-size;
}

$pseudoSize: size(0.6);
$picker-width: size(30);
$picker-item-line-height: size(3);
$picker-item-selected-before-width: size(4);
$picker-item-selected-before-height: size(2.4);

.datepicker,
.date-range-picker,
.timepicker,
.datetime-picker {
  position: relative;
  color: $black;
  font-size: size(1.4);
  user-select: none;

  /* scrollbar css */
  .scrollbar-wrap {
    .scrollbar {
      width: size(0.4) !important;
      background: #eee !important;
      border-radius: size(0.2) !important;
    }
  }

  /* vue-popper css */
  .vue-popper {
    border-color: rgba($border, 0.5) !important;

    &[x-placement^='bottom'] .arrow {
      border-bottom-color: rgba($border, 0.5) !important;
    }

    &[x-placement^='top'] .arrow {
      border-top-color: rgba($border, 0.5) !important;
    }
  }

  * {
    box-sizing: border-box;
    outline: none !important;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid $border;
    border-radius: size(0.3);

    &.focus {
      border-color: rgba($main, 0.7);
      box-shadow: 0 0 1px rgba($main, 0.3);
    }

    &.focus,
    &:hover {
      .icon-clear {
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .prefix,
  .suffix {
    display: flex;
    align-items: center;
    padding: 0 size(0.5);
  }

  .icon-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1em;
    color: $black-light;
    font-size: 0.8em;
    opacity: 0;
    pointer-events: none;
  }

  .icon-date,
  .icon-time,
  .icon-del {
    width: 1em;
    height: 1em;
  }

  .vue-input {
    display: block;
    flex: 1;
    height: 100%;
    padding: 0;
    border: none;

    &::placeholder {
      color: $black-lighter;
    }
  }

  .picker {
    z-index: 10000;
    display: block;
    box-shadow: 0 1px size(1) $shadow;

    .picker-content {
      width: $picker-width;
      padding: size(1);
    }

    .picker-head {
      padding: 0 0 size(1);
      text-align: center;

      .picker-h {
        span {
          font-size: size(1.6);
        }

        .year,
        .month,
        .date {
          cursor: pointer;

          &:hover {
            color: $main-hover;
          }
        }
      }

      .prev,
      .next {
        padding: 0 size(0.5);
        background: transparent;
        border: none;
        cursor: pointer;

        &.disabled {
          color: $black-lighter;
          cursor: default;
        }
      }

      .prev {
        float: left;
      }

      .next {
        float: right;
      }
    }

    .picker-items {
      overflow: hidden;

      .row-item {
        overflow: hidden;
        line-height: $picker-item-line-height;

        &.h {
          border-bottom: 1px solid lighten($border, 15%);

          & .item {
            color: $black-light !important;
            font-size: size(1.2);
          }
        }

        &.line {
          display: block;
          float: left;

          .item {
            display: block;
            width: 100%;
          }
        }

        .item {
          display: inline-block;
          padding: 0 size(0.8);
          line-height: inherit;
          text-align: center;
          cursor: pointer;

          &.date {
            width: calc(100% / 7);
          }

          &.month,
          &.year {
            width: calc(99% / 3);
          }

          &:hover {
            color: $main-hover;
          }

          &.not-in-month,
          &.disabled {
            color: $black-lighter !important;

            &::before {
              content: none !important;
            }
          }

          &.disabled {
            cursor: no-drop !important;
          }

          &.selected,
          &.is-now,
          &.is-in-range {
            position: relative;

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              box-sizing: border-box;
              width: 100%;
              max-width: $picker-item-selected-before-width;
              height: $picker-item-selected-before-height;
              border: 1px solid $main;
              border-radius: size(0.3);
              transform: translate(-50%, -50%);
              content: '';
            }
          }

          &.is-now {
            color: $main;

            &::before {
              background: none;
              border: 1px solid rgba($main, 0.2);
              content: '';
            }

            &.disabled {
              color: $main !important;
              opacity: 0.8;

              &::before {
                content: '' !important;
              }
            }
          }

          &.is-in-range {
            &::before {
              max-width: none;
              background: rgba($main, 0.15);
              border: none;
              border-radius: 0;
            }
          }

          &.selected {
            color: #fff;

            &::before {
              max-width: $picker-item-selected-before-width;
              background: $main;
              border-radius: size(0.3);
            }
          }
        }
      }
    }

    .btns {
      padding: size(1) 0 0;
      overflow: hidden;
      border-top: 1px solid lighten($border, 15%);

      .btn {
        float: right;
        margin: 0 0 0 size(1);
        padding: 0 size(0.4);
        color: $main;
        font-size: size(1.2);
        cursor: pointer;
      }
    }
  }

  .range-picker {
    display: flex;
  }
}

.date-range-picker {
  .vue-input {
    text-align: center;
  }
}
