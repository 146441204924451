@import 'tailwindcss/utilities';
// Formulate inputs
// -----------------------------------------------------------------------------

.formulate-input {
  margin-top: 2px;
  font-family: $formulate-font-stack;

  .formulate-input-label {
    display: block;
    margin-bottom: 0.1em;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.5;

    &--before + .formulate-input-help--before {
      margin-top: -0.25em;
      margin-bottom: 0.75em;
    }
  }

  .formulate-input-element {
    // max-width: 20em;
    margin-bottom: 0.1em;
  }

  .formulate-input-help {
    margin-bottom: 0.25em;
    color: $formulate-gray-ddd;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 1.5;
  }

  .formulate-input-errors {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .formulate-input-error,
  .formulate-file-upload-error {
    margin-bottom: 0.25em;
    color: $formulate-error;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 1.5;
    @apply dark:text-white;
  }

  // .formulate-input-group-item {
  //   margin-bottom: 1.5em;
  //   padding: 1.5em;
  //   border: 1px solid $formulate-gray;
  //   border-radius: .25em;

  //   &:last-child {
  //     margin-bottom: 1.5em;
  //   }
  // }

  &:last-child {
    margin-bottom: 0;
  }

  // Text inputs
  // -----------------------------------------------------------------------------

  &[data-classification='text'] {
    input {
      @include baseinput;
    }

    .formulate-input-element--date,
    .formulate-input-element--month,
    .formulate-input-element--week,
    .formulate-input-element--datetime-local {
      input {
        min-height: 2.2em; // shim for some browsers that collapse height like ios
      }
    }

    .formulate-input-element--search {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 2em;
        height: 100%;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.99 72.9"><path fill="%23#{str-slice("#{$formulate-gray-ddd}", 2)}" d="M71.77,66,53.87,48.1A29.94,29.94,0,1,0,30,60a29.52,29.52,0,0,0,18.1-6.1l18,17.8A4,4,0,0,0,69,72.9a4.18,4.18,0,0,0,2.9-1.2A4.09,4.09,0,0,0,71.77,66ZM30.07,51.9a21.9,21.9,0,1,1,15.5-37.4A21.37,21.37,0,0,1,52,30a22,22,0,0,1-6.4,15.5A21.54,21.54,0,0,1,30.07,51.9Z"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1em 1em;
        content: '';
        pointer-events: none;
      }
      input {
        padding-left: 2em;
      }
    }

    input[type='color'] {
      box-sizing: content-box;
      width: auto;
      min-width: 5em;
      height: 1.1em;

      @mixin color-swatch {
        display: block;
        flex: auto;
        height: 1em;
        border: 0;
        border-radius: 0.2em;
      }

      &::-webkit-color-swatch-wrapper {
        display: flex;
        align-items: center;
        padding: 0 0 0 1.5em;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.17 79.17"><path fill="%23#{str-slice("#{$formulate-gray-ddd}", 2)}" d="M40.8,22.92c-3.4-3.4-4.76-8.44-1-12.24s8.84-2.44,12.24,1c5-5,10.69-13.33,18.81-11.31a11,11,0,0,1,7.62,14.34c-1.26,3.45-4.63,6.06-7.16,8.59-.92.93-3,2.26-3.46,3.46-.42,1,1.82,2.63,2.36,4a8,8,0,0,1-12.42,9.19c-.21-.16-1.35-1.51-1.59-1.51s-.83.83-1,1L49.71,44.9,32.43,62.18c-3.37,3.38-6.6,7.14-10.26,10.21a11,11,0,0,1-4.48,2.28c-1.25.3-3.11-.22-4.18.18-1.28.48-2.42,2.65-3.68,3.4-6.05,3.61-12.64-4-8.46-9.57.73-1,2.53-1.92,3-3a14.46,14.46,0,0,0-.09-2.52,10.75,10.75,0,0,1,3.14-6.77c.92-1,1.93-1.93,2.89-2.9Zm4.4-1.5c4.19,4,8.24,8.24,12.36,12.36,2.06,2.06,5,5.59,8,2.61,4.65-4.62-5-6.8-2.42-10.78C66.3,20.7,76.4,16.48,74.84,9.45,73.62,4,67.12,2.78,63.29,6.32c-2.55,2.36-4.93,4.94-7.39,7.4-.79.78-1.8,2.28-2.88,2.73-2.14.88-3.4-1.62-4.79-2.77-2.58-2.14-6.89-.82-6.53,3C41.89,18.68,43.87,20.09,45.2,21.42Zm-1.45,4.44L27.82,41.79C22,47.57,15.89,53.14,10.41,59.2a8.23,8.23,0,0,0-1.44,2c-.93,2,.25,4.14-.5,6S4.92,69.94,4.3,72a2.34,2.34,0,0,0,2.56,3c1.11-.17,2-1.33,2.71-2.07a11.17,11.17,0,0,1,2.08-2c1.68-.94,4,.17,5.93-.57C20,69.41,22,66.73,23.76,65L34.42,54.3,53.3,35.42Z"/></svg>');
        background-repeat: no-repeat;
        background-position: left 0.1em;
        background-size: 0.9em 0.9em;
      }

      &::-webkit-color-swatch {
        @include color-swatch;
      }

      &::-moz-color-swatch {
        @include color-swatch;
      }
    }
  }

  // Slider inputs
  // -----------------------------------------------------------------------------

  &[data-classification='slider'] {
    .formulate-input-element--range {
      display: flex;
      align-items: center;
    }

    .formulate-input-element-range-value {
      margin-left: 0.5em;
      padding: 0.25em 0.3em;
      color: $formulate-gray-ddd;
      font-size: 0.9em;
      line-height: 1;
      background-color: $formulate-gray;
      border-radius: 0.25em;
      font-variant-numeric: tabular-nums;
    }

    input {
      width: 100%;
      padding: 0.5em 0;
      font-size: 1em;
      appearance: none;

      &:focus {
        outline: 0;
      }

      @mixin thumb {
        width: 1em;
        height: 1em;
        margin-top: calc(-0.5em + 2px);
        background-color: $formulate-green;
        border: 0;
        border-radius: 1em;
        cursor: pointer;
        appearance: none;
      }

      @mixin track {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        background-color: $formulate-gray;
        border-radius: 3px;
        appearance: none;
      }

      &::-webkit-slider-thumb {
        @include thumb;
      }

      &::-moz-range-thumb {
        @include thumb;
      }

      &::-ms-thumb {
        @include thumb;
      }

      &::-webkit-slider-runnable-track {
        @include track;
      }

      &::-moz-range-track {
        @include track;
      }
    }
  }

  // Textarea inputs
  // -----------------------------------------------------------------------------

  &[data-classification='textarea'] {
    textarea {
      @include baseinput;
    }
  }

  // Button inputs
  // -----------------------------------------------------------------------------

  &[data-classification='button'] {
    button {
      @include baseinput;

      display: inline-flex;
      align-items: center;
      width: auto;
      min-width: 0;
      color: white;
      font-weight: bold;
      background-color: $formulate-red;
      border-color: $formulate-red;
      cursor: pointer;

      &[disabled] {
        background-color: $formulate-gray-d;
        border-color: $formulate-gray-d;
      }

      &[data-ghost] {
        color: $formulate-red;
        background-color: white;
        border-color: currentColor;
      }

      &[data-minor] {
        display: inline-block;
        font-size: 0.75em;
      }

      &[data-danger] {
        background-color: $formulate-error;
        border-color: $formulate-error;
      }

      &[data-danger][data-ghost] {
        color: $formulate-error;
        background-color: white;
      }

      &:active {
        background-color: $formulate-red-l;
        border-color: $formulate-red-l;
      }
    }
  }

  // Select lists
  // -----------------------------------------------------------------------------

  &[data-classification='select'] {
    .formulate-input-element {
      position: relative;

      &::before {
        @include down-arrow;

        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -0.1em;
      }
    }

    select {
      @include baseinput;

      padding-right: 2em;

      &[data-placeholder-selected] {
        color: $formulate-gray-dd;
      }
    }
  }

  // Box inputs
  // -----------------------------------------------------------------------------

  &[data-classification='box'] {
    .formulate-input-wrapper {
      display: flex;
      align-items: center;
    }

    .formulate-input-element {
      display: flex;
      align-items: center;
      overflow: hidden;

      input {
        position: absolute;
        left: -999px;
        opacity: 0;
        pointer-events: none;
      }

      &-decorator {
        position: relative;
        display: block;
        width: 1.1em;
        height: 1.1em;
        border: 1px solid $formulate-gray-d;
        border-radius: 0.25em;

        &::before {
          position: absolute;
          top: 0.0625em;
          left: 0.0625em;
          display: block;
          box-sizing: border-box;
          width: calc(100% - 0.125em);
          height: calc(100% - 0.125em);
          background-position: right;
          background-size: contain;
          content: '';
        }
      }

      &[data-type='radio'] {
        .formulate-input-element-decorator {
          border-radius: 1em;

          &::before {
            top: 0.25em;
            left: 0.25em;
            width: calc(100% - 0.5em);
            height: calc(100% - 0.5em);
            border-radius: 1em;
          }
        }
      }

      input[type='checkbox']:checked {
        & ~ .formulate-input-element-decorator {
          border-color: $formulate-green;

          &::before {
            background-color: $formulate-green;
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
          }
        }
      }

      input[type='radio']:checked {
        & ~ .formulate-input-element-decorator {
          border-color: $formulate-green;

          &::before {
            background-color: $formulate-green;
          }
        }
      }

      input:focus {
        & ~ .formulate-input-element-decorator {
          border-color: $formulate-green;
        }
      }
    }

    .formulate-input-label--after {
      margin-left: 0.5em;
    }

    .formulate-input-label--before {
      margin-right: 0.5em;
    }
  }

  // Input groups
  // -----------------------------------------------------------------------------

  &[data-classification='group'] {
    .formulate-input-group-item {
      margin-bottom: 0.5em;
    }
    & > .formulate-input-wrapper > .formulate-input-label {
      margin-bottom: 0.5em;
    }

    [data-is-repeatable] {
      padding: 1em;
      border: 1px solid $formulate-gray;
      border-radius: 0.3em;

      .formulate-input-grouping {
        margin: -1em -1em 0 -1em;
      }

      .formulate-input-group-repeatable {
        position: relative;
        padding: 1em 3em 1em 1em;
        border-bottom: 1px solid $formulate-gray;

        &-remove {
          position: absolute;
          top: calc(50% - 0.65em + 0.5em);
          right: 0.85em;
          display: block;
          width: 1.3em;
          height: 1.3em;
          overflow: hidden;
          text-indent: -1000px;
          background-color: $formulate-gray-d;
          border-radius: 1.3em;
          cursor: pointer;
          transition: background-color 0.2s;

          &::before,
          &::after {
            position: absolute;
            top: calc(50% - 0.1em);
            left: 0.325em;
            display: block;
            width: 0.65em;
            height: 0.2em;
            background-color: white;
            transform-origin: center center;
            transition: transform 0.25s;
            content: '';
          }

          @media (pointer: fine) {
            &:hover {
              background-color: $formulate-error-l;

              &::after,
              &::before {
                top: calc(50% - 0.075em);
                left: 0.25em;
                width: 0.75em;
                height: 0.2em;
              }

              &::after {
                transform: rotate(45deg);
              }
              &::before {
                transform: rotate(-45deg);
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 1em;
        }
      }
    }
  }

  // File inputs
  // -----------------------------------------------------------------------------

  &[data-classification='file'] {
    .formulate-input-upload-area {
      position: relative;
      width: 100%;
      padding: 2em 0;

      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        appearance: none;
      }

      &[data-has-files] {
        padding: 0;

        input {
          display: none;
        }
      }

      &-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed $formulate-gray-dd;
        border-radius: 0.4em;
        pointer-events: none;

        &::before {
          position: absolute;
          width: 2em;
          height: 2em;
          background-color: $formulate-gray-dd;
          content: '';
          pointer-events: none;
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
          mask-repeat: no-repeat;
          mask-position: center;
        }
      }

      input:focus,
      input:hover,
      input[data-is-drag-hover] {
        & ~ .formulate-input-upload-area-mask {
          border-color: $formulate-green;

          &::before {
            background-color: $formulate-green;
          }
        }
      }
    }

    .formulate-files {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .formulate-file-progress {
        position: relative;
        right: 0.75em;
        z-index: 2;
        flex: 0 0 5em;
        width: 5em;
        height: 0.3em;
        overflow: hidden;
        background-color: $formulate-gray-d;
        border-radius: 1.25em;
        transition: height 0.25s, width 0.25s, flex-basis 0.25s;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 3;
          display: block;
          background-color: $formulate-white;
          transform: scale(0.08);
          opacity: 0;
          content: '';
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
          mask-size: 77%;
          mask-repeat: no-repeat;
          mask-position: center;
        }

        &[data-just-finished] {
          flex: 0 0 1.25em;
          width: 1.25em;
          height: 1.25em;

          &::before {
            transform: scale(1);
            opacity: 1;
            transition: transform 0.25s 0.2s, opacity 0.25s 0.2s;
          }
        }

        &[data-is-finished] {
          right: 0;
          flex: 0 0 0.3em;
          width: 0.3em;
          height: 100%;
          border-radius: 0 0.23em 0.23em 0;
          transition: height 0.25s, width 0.25s, left 0.25s, top, 0.25s, border-radius 0.25s;

          &::before {
            opacity: 0;
            transition: opacity 0.1s;
          }
        }

        .formulate-file-progress-inner {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 1%;
          background-color: $formulate-green;
        }
      }

      .formulate-file-name {
        max-width: 100%;
        padding-right: 2em;
        padding-left: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &::before {
          position: absolute;
          top: 50%;
          left: 0.7em;
          display: inline-block;
          width: 1.25em;
          height: 1.25em;
          margin-top: -0.7em;
          margin-right: 0.5em;
          background-color: $formulate-gray-dd;
          content: '';
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
          mask-repeat: no-repeat;
          mask-size: contain;
        }
      }

      .formulate-file-remove {
        position: absolute;
        right: 0.75em;
        z-index: 1;
        width: 1.25em;
        height: 1.25em;
        background-color: $formulate-gray-dd;
        border: 1px solid $formulate-gray-dd;
        border-radius: 1em;
        cursor: pointer;
        transition: transform 0.25s;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
        mask-size: 0.6em;
        mask-repeat: no-repeat;
        mask-position: center;

        @media (pointer: fine) {
          &:hover {
            transform: scale(1.5);
          }
        }
      }

      li {
        display: block;

        &[data-has-error] {
          .formulate-file-progress {
            background-color: $formulate-error-l;
          }
        }

        &[data-has-preview] {
          .formulate-file-name {
            &::before {
              display: none;
            }
          }
        }

        & + li {
          margin-top: 0.5em;
        }
      }

      .formulate-file {
        @include baseinput;

        position: relative;
        display: block;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        @mixin progress {
          height: 0.5em;
          overflow: hidden;
          border-radius: 0.5em;
          appearance: none;
        }

        ::-webkit-progress-bar {
          @include progress;
        }
      }

      .formulate-file-image-preview {
        position: relative;
        left: -1px;
        z-index: 2;
        flex: 0 0 3em;
        width: 3em;
        height: 3em;
        box-shadow: 0 0 0 1px $formulate-gray;
        transition: transform 0.25s, box-shadow 0.25s, background-color 0.25s;

        @media (pointer: fine) {
          &:hover {
            background-color: $formulate-white;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
            transform: scale(3);
            transition-delay: 0.2s;
          }
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: all 0.25s;
        }
      }
    }

    // Image uploads
    // -----------------------------------------------------------------------------

    [data-type='image'] {
      .formulate-input-upload-area {
        .formulate-input-upload-area-mask {
          &::before {
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 71.05"><path d="M82.89,0H7.1A7.12,7.12,0,0,0,0,7.11V64a7.11,7.11,0,0,0,7.1,7.1H82.9A7.11,7.11,0,0,0,90,64V7.11A7.12,7.12,0,0,0,82.89,0ZM69.28,39.35a5.44,5.44,0,0,0-8,0L50.58,50.74,32.38,30.88a5.31,5.31,0,0,0-7.92,0L4.74,52.4V7.11A2.37,2.37,0,0,1,7.11,4.74H82.9a2.37,2.37,0,0,1,2.36,2.37V56.3Z"/><circle cx="67.74" cy="22.26" r="8.53"/></svg>');
          }
        }
      }
    }
  }
}

.formulate-form-errors {
  margin: 0.75em 0;
  padding: 0;
  list-style-type: none;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .formulate-form-error {
    margin-bottom: 0.25em;
    color: $formulate-error;
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.5;
  }
}
