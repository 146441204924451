// ==========================================
//   RESET
// ==========================================

dl,
dt,
dd {
  margin: 0;
}

// ==========================================================================
// # VARIABLES
// ==========================================================================

// COLORS
$white: #fff;
$off-white: #fafafa;
$black: #323232;
$grey: #6c7a89;
$grey-light: #95a5a6;
$blue: #2574a9;
$dark-blue: #34495e;
$green: #006a6e;

// UNITS
$spacing-unit: 40px;
$half-spacing-unit: $spacing-unit / 2;

// BORDER
$accordion-border: solid 2px $grey-light;

// ANIMATION
$transition-time: 0.2s;

@mixin base-trans {
  transition: all ease-in-out $transition-time;
}

// LINKS
@mixin link-underline($color: currentColor, $size: -2px) {
  box-shadow: inset 0 $size $color;
}

@mixin active-link() {
  color: $blue;

  .icon-link__text {
    @include link-underline($blue, -3px);
  }

  svg {
    fill: $blue;
  }
}

// ==========================================================================
// # BASE
// ==========================================================================

.vh {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.vh.focusable:active,
.vh.focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

// .inline-list {
//   display: block;
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

.inline-list__item {
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: $spacing-unit;
  }
}

.icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-unit/8;
  text-align: center;
  text-decoration: none;

  &:focus {
    @include active-link;

    outline: auto 2px $blue;
  }

  svg {
    @include base-trans;

    margin-left: 0.5rem;
    fill: $dark-blue;
  }
}

@media screen and (min-width: 768px) {
  .icon-link:hover {
    @include active-link;
  }
}

.icon-link__text {
  @include base-trans;
  @include link-underline;
}

.logo {
  display: block;
  max-width: 280px;
  margin: 0 auto $spacing-unit;
}

// ==========================================================================
// # ESSENTIAL STYLES
// ==========================================================================
.badger-accordion-panel {
  position: relative;
  max-height: 75vh;
  overflow: hidden;
  background-color: $off-white;

  // scss-lint:disable ImportantRule
  &.-ba-is-hidden {
    max-height: 0 !important;
  }

  // transition is added via `badger-accordion--initialized` to stop animation on initalition
  .badger-accordion-initialized & {
    transition: max-height ease-in-out 0.2s;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #eff1f0;
    content: '';
  }
}

// ==========================================================================
// # DEMO ACCORDION
// ==========================================================================

.badger-accordion {
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
}
.badger-accordion-trigger-icon {
  position: relative;
  display: block;
  width: $spacing-unit;
  height: $spacing-unit;
  margin-left: auto;
  transition: all ease-in-out $transition-time;

  &::after,
  &::before {
    position: absolute;
    top: 10px;
    width: 30px;
    height: 3px;
    background-color: #333;
    transition: all ease-in-out (($transition-time / 3) * 2);
    content: '';
  }

  &::before {
    left: 1px;
    transform: rotate(45deg) translate3d(7px, 22px, 0);
    transform-origin: 100%;
  }

  &::after {
    right: 1px;
    transform: rotate(-45deg) translate3d(-6px, 23px, 0);
    transform-origin: 0;
  }
}

.badger-accordion-header {
  &:not(:last-of-type) {
    border-bottom: 1px solid #eff1f0;
  }
}
.badger-accordion-trigger-title {
  font-size: 1.2rem;
  transition: ease-in-out 0.3s;
}

.badger-accordion-trigger {
  display: flex;
  align-content: space-between;
  align-items: center;
  width: 100%;
  padding: $half-spacing-unit;
  color: $dark-blue;
  font-size: 1.25rem;
  line-height: 1;
  text-align: left;
  background-color: $white;
  border: 0;
  border-radius: 0;
  transition: all ease-in-out $transition-time;

  &[aria-expanded='true'] {
    .badger-accordion-trigger-icon {
      &::before {
        transform: rotate(45deg) translate3d(13px, 14px, 0);
      }

      &::after {
        transform: rotate(-45deg) translate3d(-13px, 14px, 0);
      }
    }
  }
  &::-moz-focus-inner {
    border: none;
  }
  &:focus,
  &:hover {
    background-color: $green;
    outline: none;
    cursor: pointer;

    .badger-accordion-trigger-title {
      color: $white;
    }

    .badger-accordion-trigger-icon {
      &::after,
      &::before {
        background-color: $white;
      }
    }
  }

  // Removing "inner outline" for Firefox
}

.badger-accordion-panel-inner {
  padding: $half-spacing-unit $half-spacing-unit 47px;
}

@media screen and (max-width: 767px) {
  .badger-accordion-trigger-icon {
    display: none;
    padding: $half-spacing-unit;
  }
}
