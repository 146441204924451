/* =========================================================================================
    File Name: _charts.scss
    Description: Styles for charts.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

.echarts {
  width: 100% !important;
}
