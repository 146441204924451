// get color var css
@function -color($color, $alpha: 1) {
  @return unquote('rgba(var(--vs-#{$color}), #{$alpha})'); }

@function -var($var) {
  @return unquote('var(--vs-#{$var})'); }

.loading-enter-active, .loading-leave-active {
  transition: opacity .5s; }

.loading-enter, .loading-leave-to {
  opacity: 0; }


.vs-loading {
  --vs-color: var(--vs-primary);
  --vs-background: 255,255,255;
  --vs-opacity: .6;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -color('background', -var('opacity'));
  transition: all .25s ease;
  padding: 20px;
  border-radius: inherit;
  &--target {
    position: absolute; }
  &__load {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__text {
      font-size: .75em;
      margin: 7px;
      font-weight: bold;
      color: -color('color'); }
    &__percent {
      position: relative;
      font-size: .65rem;
      font-weight: bold;
      color: -color('color');
      margin-top: 1px;
      z-index: 200; }
    &__animation {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; } } }

.vs-loading__progress {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 4px;
  background: -color('color', .2);
  &__bar {
    background: -color('color');
    height: 100%;
    position: relative;
    border-radius: 0px 10px 10px 0px; } }


.vs-loading--default {
  .vs-loading__load__animation {
    border-radius: 50%;
    &__1 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid -color('color');
      border-radius: inherit;
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      animation: rotate .8s ease infinite;
      top: 0px; }
    &__2 {
      top: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px dashed -color('color');
      border-radius: inherit;
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      animation: rotate .8s linear infinite;
      opacity: .2; }
    &__3 {
      display: none; } } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.vs-loading--waves {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    &__1 {
      width: 0px;
      background: transparent;
      height: 0px;
      position: absolute;
      animation: waves .7s ease infinite;
      box-shadow: 0px 0px 10px 0px -color('color',.5);
      border-radius: 50%; }
    &__2 {
      width: 0px;
      background: transparent;
      height: 0px;
      position: absolute;
      animation: waves 1.4s linear infinite;
      box-shadow: 0px 0px 10px 0px -color('color',.5);
      border-radius: 50%; }
    &__3 {
      width: 0px;
      background: transparent;
      height: 0px;
      position: absolute;
      animation: waves 1.75s ease infinite;
      box-shadow: 0px 0px 10px 0px -color('color',.5);
      border-radius: 50%; } } }

@keyframes waves {
  0% {
    width: 0px;
    height: 0px; }
  100% {
    height: 50px;
    width: 50px;
    opacity: 0; } }

.vs-loading--corners {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    &__1 {
      width: 100%;
      background: transparent;
      height: 100%;
      position: absolute;
      animation: corners 1s ease infinite;
      border-radius: 50%;
      border: 2px solid -color('color',1); }
    &__2 {
      display: none; }
    &__3 {
      display: none; } }

 }  // corners
@keyframes corners {
  0% {
    border-radius: 50%;
    transform: rotate(0deg); }
  25% {
    border-radius: 50% 50% 50% 15%; }
  50% {
    border-radius: 50% 50% 15% 30%; }
  75% {
    border-radius: 50% 15% 30% 30%; }
  100% {
    border-radius: 50%;
    transform: rotate(-180deg); } }

.vs-loading--border {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &__1 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid -color('color');
      border-radius: inherit;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      animation: rotate 1s linear infinite;
      top: 0px; }
    &__2 {
      top: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px dashed -color('color');
      border-radius: inherit;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      animation: rotate 1s linear infinite .2s; }
    &__3 {
      top: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px dashed -color('color');
      border-radius: inherit;
      animation: rotate 1s linear infinite .4s;
      opacity: .2; } } }

.vs-loading--points {
  .vs-loading__load__percent {
    position: absolute;
    top: -10px; }
  .vs-loading__load__animation {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: auto;
    &__1 {
      width: 8px;
      height: 8px;
      background: -color('color');
      border-radius: 50%;
      margin: 3px;
      animation: points .75s ease infinite; }
    &__2 {
      width: 8px;
      height: 8px;
      background: -color('color');
      border-radius: 50%;
      margin: 3px;
      animation: points .75s ease infinite .25s; }
    &__3 {
      width: 8px;
      height: 8px;
      background: -color('color');
      border-radius: 50%;
      margin: 3px;
      animation: points .75s ease infinite .5s; } } }


@keyframes points {
  0% {
    transform: translate(0px, 0px); }
  50% {
    transform: translate(0, -15px); }
  100% {
    transform: translate(0px, 0px); } }

.vs-loading--square {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    &__1 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid -color('color');
      border-radius: inherit;
      animation: rotateSquare 4s ease infinite;
      top: 0px; }
    &__2 {
      top: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid -color('color');
      border-radius: inherit;
      animation: rotateSquare 4s ease infinite reverse;
      background: -color('background', 1); }
    &__3 {
      display: none; } } }

@keyframes rotateSquare {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

.vs-loading--gradient {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    &__1 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 0px;
      border-radius: inherit;
      animation: rotate 1s linear infinite;
      top: 0px;
      background: linear-gradient(0deg, -color('background', 0) 33%, -color('color', 1) 100%);
      border-radius: 50%; }
    &__2 {
      top: 2px;
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 0px;
      border-radius: inherit;
      background: -color('background', 1);
      border-radius: 50%; }
    &__3 {
      display: none; } } }

.vs-loading--rectangle {
  .vs-loading__load__percent {
    position: absolute;
    bottom: -2px; }
  .vs-loading__load__animation {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &__1 {
      position: absolute;
      width: 15px;
      height: 15px;
      border: 0px;
      border-radius: inherit;
      animation: rectangle 1s ease infinite;
      background: -color('color', 1); }
    &__2 {
      position: absolute;
      width: 15px;
      height: 15px;
      border: 0px;
      border-radius: inherit;
      animation: rectangle 1s ease-out infinite;
      background: -color('color', .2); }
    &__3 {
      display: none; } } }

@keyframes rectangle {
  0% {
    transform: translate(-50px); }
  50% {
    transform: translate(50px); }
  100% {
    transform: translate(-50px); } }

.vs-loading--circles {
  .vs-loading__load__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    &__1 {
      position: absolute;
      width: 40px;
      height: 40px;
      animation: rotate 1s ease infinite;
      border-radius: 50%;
      border: 2px solid -color('color', 1);
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent; }
    &__2 {
      position: absolute;
      width: 50px;
      height: 50px;
      animation: rotate 1s ease-in-out infinite;
      border-radius: 50%;
      border: 2px dashed -color('color', 1);
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent; }
    &__3 {
      position: absolute;
      width: 60px;
      height: 60px;
      animation: rotate 1s linear infinite reverse;
      border-radius: 50%;
      border: 2px solid -color('color', 1);
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent; } } }

.vs-loading--square-rotate {
  .vs-loading__load__percent {
    position: absolute;
    bottom: 30px; }
  .vs-loading__load__animation {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 60px;
    height: 60px;
    &__1 {
      position: absolute;
      width: 25px;
      height: 25px;
      animation: squareRotate 3s ease infinite;
      background: -color('color', 1); }
    &__2 {
      display: none; }
    &__3 {
      display: none; } } }

@keyframes squareRotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg); }
  25% {
    transform: rotateX(180deg) rotateY(0deg); }
  50% {
    transform: rotateX(180deg) rotateY(180deg); }
  75% {
    transform: rotateX(0deg) rotateY(180deg); }
  100% {
    transform: rotateX(0deg) rotateY(0deg); } }

.vs-loading--scale {
  .vs-loading__load__percent {
    position: absolute;
    bottom: 40px; }
  .vs-loading__load__animation {
    display: flex;
    align-items: cneter;
    justify-content: center;
    width: 40px;
    height: 40px;
    &__1 {
      position: relative;
      width: 5px;
      height: 5px;
      background: -color('color', 1);
      margin: 3px;
      animation: scale .8s ease infinite;
      border-radius: 5px; }
    &__2 {
      position: relative;
      width: 5px;
      height: 5px;
      background: -color('color', 1);
      margin: 3px;
      animation: scale .8s ease infinite .2s;
      border-radius: 5px; }
    &__3 {
      position: relative;
      width: 5px;
      height: 5px;
      background: -color('color', 1);
      margin: 3px;
      animation: scale .8s ease infinite .4s;
      border-radius: 5px; } } }

@keyframes scale {
  0% {
    height: 5px; }
  50% {
    height: 25px; }
  0% {
    height: 5px; } }
