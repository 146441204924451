<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { set } from '@/services/app-storage';
import MarketingHeader from '@/modules/marketing/MarketingHeader.vue';
import VueTour1 from '@/components/v-tour/VueTour1.vue';
import { appModule } from '@/store/modules/app.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import contentModule from '@/store/modules/content.module';
import userModule from '@/store/modules/user/user.module';
import { Socket } from 'vue-socket.io-extended';
import { mapState } from 'vuex';
import { SUBSCRIPTION_RENEWED } from '@/plugins/event-bus/notification/notification.constants';
import { Notifications } from './store/modules/notification.module';
import isEmpty from './app/utils/object.utils';
import VueTour2 from '@/components/v-tour/VueTour2.vue';
import service from './services/app-http-client';

@Component({
  components: {
    MarketingHeader,
    VueTour1,
    VueTour2,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    // all titles will be injected into this template
    titleTemplate: '%s | Check-in Buddy',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content:
          'To help the hospitality industry safely navigate the tricky waters of a post-COVID-19 world, Check-In Buddy has created a cutting-edge digital platform to not only address the immediate needs of the sector but also enhance your visitors’ experience, simplify your check-in process, and allow you to access your “front desk” from anywhere in the world.',
      },
    ],
  },
  computed: {
    ...mapState('app', ['tourActive']),
  },
})
export default class App extends Vue {
  private vueAppClasses: string[] = [];

  cookieAccept = false;

  showUpgradeUI = false;

  id = null;

  @Watch(`$store.state.tenant.tenant`, { deep: true })
  handleAuth(isAuthenticated: boolean) {
    if (isAuthenticated) {
      this.$socket.client.open();
    }
  }

  @Watch('$socket.connected')
  handleWsConnected(_val: any) {
    this.$socket.client.emit('join', { user: userModule.user, tenant: tenantModule.tenant }, (data: any) => Notifications.setMessage(data));
  }

  @Socket('subscription:updated') // --> listens to the event with given name, e.g. `tweet`
  async onSubscriptionUpdate(payload: any) {
    console.log(payload);
    await tenantModule.getTenant();
    this.$emitter.emit('toast', SUBSCRIPTION_RENEWED);
  }

  @Socket('tenant') // --> listens to the event with given name, e.g. `tweet`
  async onTenantUpdate(payload: any) {
    tenantModule.setTenant(payload);
  }

  async mounted(): Promise<void> {
    appModule.updateWindowWidth(window.innerWidth);
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.cookieAccept = appModule.cookiesAccepted;
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
      appLoading.style.display = 'none';
    }
    await contentModule.getSubContent();
  }

  get hasCookie(): boolean {
    return this.cookieAccept;
  }

  get theme(): string {
    return appModule.darkMode ? 'dark' : 'light';
  }

  get tourActive() {
    return isEmpty(appModule.tourActive) && this.$route.path.includes('organisation');
  }

  acceptCookies(accepted: boolean): void {
    this.cookieAccept = true;
    set(accepted, 'cookiesAccepted');
  }

  async accept(): Promise<void> {
    this.showUpgradeUI = false;
    await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
  }

  async created(): Promise<void> {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpgradeUI = true;
      });
    }

    // const res = await service.get('http://localhost:3000/v1/api/billing/test');

    // const script = document.createElement('script');
    // script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${res.data.id}`;
    // const tag = document.getElementById('hubspot');
    // if (tag) tag.appendChild(script);
    document.documentElement.setAttribute('dir', 'ltr');
  }

  setAppClasses(classesStr: string): void {
    this.vueAppClasses.push(classesStr);
  }

  handleWindowResize = (): void => {
    appModule.updateWindowWidth(window.innerWidth);
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  handleScroll = (): void => {
    this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
  };

  get connected() {
    return this.$socket?.connected ? 'Connected' : 'Disconnected';
  }
}
</script>

<template>
  <div id="app" :class="theme">
    <div class="hidden">
      <pre>Socket - {{ connected }} - development</pre>
    </div>
    <router-view />
    <portal-target name="modal"></portal-target>
    <VueTour1 v-if="tourActive"></VueTour1>
    <VueTour2 v-if="tourActive"></VueTour2>
  </div>
</template>

<style lang="css">
#app {
  height: 100%;
  background: white;
}
</style>
