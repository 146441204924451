











































































































































.vs-divider {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  clear: both;
  width: 100%;
  margin: 15px 0;
  .after,
  .before {
    position: relative;
    display: block;
    width: 100%;
  }
}
.vs-divider--text {
  position: relative;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 0.9375em;
  white-space: nowrap;
  background: rgb(255, 255, 255);
  cursor: default;
  user-select: none;
}

.vs-divider--icon {
  font-size: 1.25em;
}
